<template>
  <div>
    <draggable
      :component-data="getComponentData()"
      class="image-wrapper"
      :list="images"
      group="tasks"
    >
      <transition-group>
        <div
          class="image-container"
          v-for="(image, i) in images"
          :key="i + Math.random()"
        >
          <img class="image" :src="image.image" @click="index = i" />
          <button
            v-on:click="$emit('delete-image', image.id)"
            class="btn btn-danger image-button"
          >
            Delete
          </button>
          <span
            style="
              font-size: 12px;
              text-align: center;
              color: gray;
              display: flex;
              justify-content: center;
            "
            >{{ image.approved ? "" : "Image hasn't been approved" }}</span
          >
        </div>
      </transition-group>
    </draggable>
    <vue-gallery-slideshow
      :images="imageList"
      :index="index"
      @close="index = null"
    ></vue-gallery-slideshow>
    <div
      v-if="images && images.length === 0"
      style="display: flex; flex-direction: column; align-items: center;"
    >
      <span
        style="color: lightgray; font-size: 50px; margin-top: 20px;"
        class="fas fa-images"
      ></span>
      <p class="text-center" style="margin-top: 10px; color: lightgray;">
        No images have been added
      </p>
    </div>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";
import draggable from "vuedraggable";
export default {
  name: "Images",
  components: {
    VueGallerySlideshow,
    draggable,
  },
  props: ["onImagesChange", "images"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    imageList: function () {
      if (this.images) {
        return this.images.map((value, index) => {
          return value.image;
        });
      }
      return this.images;
    },
  },
  methods: {
    onEnd(e) {
      this.onImagesChange(e);
    },
    getComponentData() {
      return {
        on: {
          end: this.onEnd,
        },
      };
    },
  },
};
</script>

<style scoped>
.image-wrapper {
  display: flex;
  flex-direction: column;
}
.image-wrapper > span {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.image-container {
  width: 200px;
  display: inline-block;
  margin: 15px;
}
.image-button {
  width: 100%;
  margin: 0px;
}
.image {
  width: 200px;
  height: 200px;
  background-size: cover;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid lightgray;
  object-fit: contain;
  cursor: grab;
}
</style>
