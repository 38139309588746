<template>
  <div>
    <div class="card" style="padding: 10px; margin-top: 10px" v-if="event">
      <div class="upper-container">
        <div>
          <h3 class="event-header">{{ event.name }}</h3>
          <p class="event-price" style="color: green">
            Entry fee: {{ event.fee ? event.fee + " kr" : "Free" }}
          </p>
        </div>
        <div>
          <p class="event-time" style="color: gray">
            {{ "Date: " + selectedDate }}
          </p>
          <div class="event-time">
            <span style="color: gray">Time: {{ event.timeFrom }}</span>
            <span style="color: gray">-</span>
            <span style="color: gray">{{ event.timeTo }}</span>
          </div>
        </div>
      </div>
      <p class="event-description">{{ event.description }}</p>
      <div
        v-if="event.connectedEventDates && event.connectedEventDates.length > 0"
      >
        <label style="display: block; font-weight: bold"
          >Event is repeated on:</label
        >
        <div
          class="repeatedDay"
          v-for="(item, index) in event.connectedEventDates"
          :key="index"
        >
          <span>{{ getDateFromKey(item) }}</span>
        </div>
      </div>
      <div class="event-buttons">
        <button
          v-on:click="$emit('add-event-press', eventType, event._id)"
          style="width: 150px"
          class="btn btn-primary"
        >
          Edit
        </button>
        <button
          @click="showDeleteModal()"
          style="width: 150px; margin-left: 6px"
          class="btn btn-danger"
        >
          Delete
        </button>

        <div
          class="modal fade"
          id="deleteEventModal"
          tabindex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="deleteEventModalTitle"
          aria-hidden="true"
        >
          <div
            style="max-width: 800px; margin: auto; margin-top: 20px"
            class="modal-dialog modal-dialog"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="deleteEventModalLongTitle">
                  Delete event
                </h5>
              </div>
              <div
                class="modal-body"
                v-if="
                  event.connectedEventDates &&
                  event.connectedEventDates.length > 1
                "
              >
                Do you want to delete all repeated events or only this event?
              </div>
              <div class="modal-body" v-else>
                Are you sure you want to delete this event?
              </div>
              <div class="modal-footer">
                <button @click="cancelDelete()" class="btn btn-primary">
                  Cancel
                </button>
                <button
                  v-if="
                    event.connectedEventDates &&
                    event.connectedEventDates.length > 1
                  "
                  v-on:click="
                    $emit(
                      'delete-event',
                      selectedDateKey,
                      selectedDay.toLowerCase(),
                      false,
                      'many'
                    )
                  "
                  class="btn btn-danger"
                >
                  Delete all repeated events
                </button>
                <button
                  v-on:click="
                    $emit(
                      'delete-event',
                      selectedDateKey,
                      selectedDay.toLowerCase(),
                      false,
                      'one'
                    )
                  "
                  class="btn btn-danger"
                >
                  Delete this event only
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- <button
          v-on:click="
            $emit(
              'delete-event',
              eventType,
              event._id,
              true,
              selectedDay.toLowerCase(),
              selectedDateKey
            )
          "
          style="width: 300px; margin-left: 6px;"
          :class="event.isActive ? 'btn btn-primary' : 'btn btn-danger'"
        >
          {{
          event.isActive
          ? "Activate (show event)"
          : "Deactivate (temporarily not show)"
          }}
        </button>-->
      </div>
    </div>
    <!-- <p v-else style="color: lightgray" class="text-center">No events have been added on this day</p> -->
    <!-- {{selectedDate}}
    {{selectedDay}}
    {{selectedDateKey}}
    {{getEvent}}
    -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  created: function () {},
  props: [
    "event",
    "selectedDay",
    "selectedDate",
    "selectedDateKey",
    "eventType",
  ],
  data() {
    return {
      dateKey: "",
    };
  },
  methods: {
    getDateFromKey(dateKey) {
      return moment(dateKey, "YYYY-MM-DD").format("ll");
    },
    showDeleteModal() {
      $("#deleteEventModal").modal("show");
    },
    cancelDelete() {
      $("#deleteEventModal").modal("hide");
    },
  },
  // computed: {
  //   getSelectedKey: function() {
  //     if (this.selectedDateKey === "today") {
  //       let date = new Date();
  //       let year = date.getFullYear();
  //       let month = date.getMonth() + 1;
  //       let day = date.getDate();
  //       month = month.toString().length === 1 ? "0" + month : month;
  //       day = day.toString().length === 1 ? "0" + day : day;
  //       let newDateKey = `${year}-${month}-${day}`;
  //       return newDateKey;
  //     } else {
  //       return this.selectedDateKey;
  //     }
  //     return this.selectedDateKey;
  //   }
  // }
};
</script>

<style scoped>
.repeatedDay {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  background-color: gray;
  padding: 5px;
  border-radius: 5px;
  color: white;
}
.upper-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 500px) {
  .upper-container {
    flex-direction: column;
  }
  .event-header {
    text-align: center;
  }
  .event-price {
    text-align: center;
  }
  .event-time {
    text-align: center;
  }
  .event-description {
    margin-top: 10px;
    text-align: center;
  }
  .event-buttons {
    display: flex;
  }
}
</style>
