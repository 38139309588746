<template>
  <div>
    <CardText 
      title="Terms of services" 
      subTitle="for Barhopp.live website" 
      type="termsweb"
       />
  </div>
</template>

<script>
import CardText from "./CardText";
export default {
  components: {
    CardText,
  },
  data() {
    return {};
  },
};
</script> 

<style scoped>
</style>