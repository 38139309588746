<template>
	<div id="app">
		<!-- <img src="./assets/party.jpg" /> -->

		<!-- <img src="./assets/logo.png" /> -->
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
	};
</script>

<style>
	:root {
		--main-color: #6891e3;
	}
	h5 {
		font-weight: 600;
		font-size: 18px;
	}
	.loader {
		margin-top: 30px;
		border: 16px solid #f3f3f3; /* Light grey */
		border-top: 16px solid #3498db; /* Blue */
		border-radius: 50%;
		width: 120px;
		height: 120px;
		animation: spin 2s linear infinite;
	}
	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	.modal-dialog {
		padding-left: 0px;
	}
	.main-container {
		background-image: linear-gradient(
				to right,
				rgba(14, 11, 56, 0.8) 0%,
				rgba(9, 9, 121, 0.8) 47%,
				rgba(68, 0, 80, 0.8) 100%
			),
			url('./assets/cocktailsblurless.jpg');
		/* height: 1000px; */
		min-height: 100vh;
		position: absolute;
		width: 100%;
		background-size: cover;
	}
	.flex {
		display: flex;
		align-items: center;
	}
	.input-addition {
		margin-left: 4px;
	}
	.card-app {
		width: 500px;
		margin: auto;
		background-color: rgba(0, 0, 0, 0.3);
		border-radius: 10;
		border: 0px;
		padding: 50px;
		margin-top: 100px;
	}

	@media only screen and (max-width: 500px) {
		.card-app {
			width: 100%;
			min-width: 200px;
			max-width: 500px;
			margin: auto;
			background-color: rgba(0, 0, 0, 0.3);
			border-radius: 10;
			border: 0px;
			padding: 50px;
			margin-top: 100px;
		}
	}

	.sign-container {
		margin-bottom: 50px;
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
	}
	.input-label {
		color: white;
		font-weight: 500;
		font-size: 14px;
		text-transform: uppercase;
		opacity: 0.8;
		margin-left: 10px;
	}
	.input {
		border-radius: 20px;
		width: 100%;
		height: 40px;
		background-color: rgba(255, 255, 255, 0.2);
		border: 0px;
		padding-left: 10px;
		color: white;
	}
	select > option {
		color: black;
	}
	.special-select {
		width: 100%;
		height: 35px !important;
		padding-left: 5px;
		color: gray;
	}
	.special-select > option {
		color: gray;
	}
	.button {
		width: 100%;
		border-radius: 20px;
		margin-top: 30px;
		font-weight: 600;
		text-transform: uppercase;
	}
	.timebox {
		width: 100%;
	}
	.timebox > input {
		width: 100% !important;
		height: 38px !important;
		border-radius: 4px;
	}
	.save-btn {
		width: 200px;
		margin: 20px;
	}
	.btn-primary,
	.btn-primary:hover {
		background-color: #5b7ff9 !important;
		border-color: #5b7ff9 !important;
	}
	.btn-info,
	.btn-info:hover {
		background-color: #8773d8 !important;
		border-color: #8773d8 !important;
	}
	a.badge-info,
	a.badge-info:focus,
	a.badge-info:hover {
		background-color: #8773d8 !important;
		border-color: #8773d8 !important;
	}

	.card-space {
		margin-top: 30px;
	}
	.flash {
		height: 100px;
		position: fixed;
		display: list-item;
		right: 4px;
		top: 4px;
		z-index: 9999;
		text-decoration: none;
		list-style: none;
	}
	.header-image {
		background-image: linear-gradient(
				to right,
				rgba(14, 11, 56, 0.8) 0%,
				rgba(9, 9, 121, 0.8) 47%,
				rgba(68, 0, 80, 0.8) 100%
			),
			url('assets/drinks.jpg');
		background-position-y: 50%;
		background-size: cover;
		width: 100%;
		height: 400px;
		/* opacity: 0.5; */
	}

	.header-image-cover {
		background-color: black;
		opacity: 1;
	}

	.badge {
		cursor: auto;
	}
	.modal-dialog {
		max-width: 100%;
		padding-left: 17px;
	}
	.required-ast {
		color: red;
	}
	.error-text {
		color: #960000;
	}
	.vue__time-picker {
		width: 100% !important;
	}
	.vue__time-picker .dropdown ul li:not([disabled]).active,
	.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
	.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
		background-color: #6891e3;
	}
	.main-color-border {
		border: 1px solid #6891e3;
		background-color: #6891e3;
	}

	.sub-color-border {
		border: 1px solid pink;
		background-color: pink;
	}
	.column-label {
		font-weight: 600;
		font-size: 15px;
	}
	.column-wrap {
		border-radius: 10px;
		margin-top: 10px;
		padding: 10px;
		margin: 15px;
		flex: 1;
		flex-wrap: wrap;
		min-width: 200px;
	}
	.card-icon {
		font-size: 40px;
		text-align: center;
		display: flex;
		margin: auto;
		margin-top: 10px;
		margin-bottom: 10px;
	}
	@media screen and (max-width: 250px) {
		.column-wrap {
			width: 100%;
		}
	}
</style>
