<template>
  <div>
    <div class="main-container">
      <div>
        <flash-message class="flash"></flash-message>
        <div class="card-app">
          <div class="card-inner-container">
            <h1 class="text-center header">Barhopp</h1>
            <p class="text-center" style="font-size: 16px">Resetting two factor authentication</p>
            <div
              style="margin: auto; margin-top: 50px; text-align: center"
              class="loader"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
export default {
  mounted() {
    let urlArray = window.location.href.split("/");
    let token = urlArray[urlArray.length - 1];
    if (!token || token.length < 20) {
      token = urlArray[urlArray.length - 2];
    }
    if (token && token.length > 20) {
      AuthenticationService.resetTwoFactor(token).then((response) => {
        this.flash(response.data.message, response.data.isSuccess ? 'success' : 'error');
        setTimeout(() => {
          this.$router.push(`/panel`);
        }, 2000);
     }).catch((err) => {
        console.log("errr: ", err);
        this.flash("This link did not work. Maybe it has expired.", false);
        setTimeout(() => {
          this.$router.push(`/panel`);
        }, 2000);
      });    
    } else {
      this.flash("No token found in this link.", false);
        setTimeout(() => {
          this.$router.push(`/panel`);
        }, 2000);
    }
  }
};
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
  color: white;
  /* margin-bottom: 50px; */
  font-weight: 600;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
p {
  color: white;
}
.error-text {
  color: red;
}

.success-text {
  color: green;
}
</style>
