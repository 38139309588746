<template>
	<div>
		<div class="form-row">
			<div class="form-group col-md-6">
				<label :for="'event-type' + this.action">
					Event name
					<span class="required-ast">(*)</span>
				</label>
				<input
					placeholder="Live music for example"
					maxlength="30"
					:id="'event-type' + this.action"
					class="form-control"
					v-model="nName"
				/>
				<p class="error-text">
					{{ this.isLongEnough(nName, 4, 'Text is not long enough') }}
				</p>
			</div>

			<div class="form-group col-md-2">
				<label :for="'event-from' + this.action" style="width: 100%">
					From
					<span class="required-ast">(*)</span>
				</label>
				<vue-timepicker
					:id="'event-from' + this.action"
					required
					maxlength="5"
					:minute-interval="5"
					v-model="nFrom"
					:manual-input="true"
					class="timebox"
					format="HH:mm"
				></vue-timepicker>
				<p class="error-text">
					{{ this.isMilitaryTime(nFrom) ? '' : 'From time is invalid' }}
				</p>
			</div>
			<div class="form-group col-md-2">
				<label :for="'event-to' + this.action">
					To
					<span class="required-ast">(*)</span>
				</label>
				<vue-timepicker
					:id="'event-to' + this.action"
					required
					v-model="nTo"
					maxlength="5"
					:minute-interval="5"
					:manual-input="true"
					style="width: 100%"
					class="timebox"
					format="HH:mm"
				></vue-timepicker>
				<p class="error-text">
					{{ this.isMilitaryTime(nTo) ? '' : 'To time is invalid' }}
				</p>
			</div>
			<div class="form-group col-md-2">
				<label for="event-fee">Fee (Optional)</label>
				<input
					maxlength="5"
					type="number"
					id="event-fee"
					class="form-control"
					v-model="nFee"
				/>
			</div>
		</div>
		<div class="form-row" v-if="nFee && nFee > 0">
			<div class="form-group col-md-12">
				<label>Link to ticket sale</label>
				<input class="form-control" id="event-link" v-model="nLinkPay" />
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label for="event-description" style="width: 100%">
					Event description
					<span class="required-ast">(*)</span>
				</label>
				<textarea
					placeholder="What is the event about? Why would people want to go?"
					v-model="nDescription"
					maxlength="1000"
					id="event-description"
					class="form-control"
				></textarea>
				<p class="error-text">
					{{ this.isLongEnough(nDescription, 7, 'Text is not long enough') }}
				</p>
			</div>
		</div>

		<div class="form-row">
			<div class="form-group col-md-6">
				<label>Repeat event (Optional)</label>
				<div>
					<div style="display: flex; align-items: center">
						<datepicker
							:disabledDates="disabledDates"
							@selected="onDateSelected"
							v-model="repeatdate"
							:monday-first="true"
							name="uniquename"
						></datepicker>
						<button @click="onAddDate" class="btn btn-primary add-date-button">
							Add date
						</button>
					</div>
					<div
						v-if="
							this.connectedEventDates && this.connectedEventDates.length > 0
						"
						style="margin-top: 10px"
					>
						<label style="display: block">Event is repeated on:</label>
						<div
							class="repeatedDate"
							v-for="(dateKey, index) in this.connectedEventDates"
							:key="index"
						>
							<!-- {{ this.getDateFromKey(date) }} -->
							{{ getDateFromKey(dateKey) }}
							<a
								style="
                  color: white;
                  margin-left: 5px;
                  text-decoration: none;
                  margin-right: 3px;
                "
								@click="closeRepeatedDate(dateKey)"
								href="javascript:void(0)"
								>X</a
							>
						</div>
					</div>
					<div v-else style="margin-top: 10px">
						<label style="display: block">Event is not repeated</label>
					</div>
				</div>
			</div>
			<div class="form-group col-md-6">
				<div v-if="(this.removeImage && this.imageUrl) || !this.imageUrl">
					<label for="event-image" style="width: 100%"
						>Event Image (Optional)</label
					>
					<input
						id="event-image"
						accept="image/jpeg, image/jpg, image/png"
						@change="uploadImage"
						type="file"
						class="form-control-file"
					/>
				</div>
				<div v-if="this.imageUrl" class="image-container">
					<p class="sub-text">
						Please note that image proportions might not be accurate here.
					</p>
					<img
						:class="
							this.removeImage && this.imageUrl ? 'opacity image' : 'image'
						"
						:src="this.imageUrl"
					/>
					<button
						@click="onRemoveImagePress"
						:class="
							this.removeImage
								? 'btn btn-primary remove-image-button'
								: 'btn btn-danger remove-image-button'
						"
					>
						{{ this.removeImage ? "Don't remove image" : 'Remove image' }}
					</button>
					<p v-if="image ? !image.approved : false" class="sub-text">
						Image has not been approved
					</p>
					<p
						style="
              width: 200px;
              font-size: 13px;
              color: red;
              font-weight: bold;
              text-align: center;
            "
					>
						{{ this.removeImage ? 'This image will be removed on save.' : '' }}
					</p>
				</div>
			</div>
		</div>

		<div style="display: block; margin-top: 20px">
			<button
				style="margin-right: 10px;"
				v-on:click="
					$emit('on-manage-event-press', id, {
						id,
						name: nName,
						fee: nFee,
						from: nFrom,
						to: nTo,
						description: nDescription,
						linkPay: nLinkPay,
						action: action,
						image: image,
						connectedEventDates: connectedEventDates,
						imageName: imageName,
						removeImage: removeImage,
					})
				"
				class="btn btn-primary"
			>
				{{ buttonText }}
			</button>
			<button
				style="margin-right: 10px;"
				v-if="
					secondButtonText &&
						this.connectedEventDates &&
						this.connectedEventDates.length > 0
				"
				v-on:click="
					$emit('on-manage-event-press', id, {
						id,
						name: nName,
						fee: nFee,
						from: nFrom,
						to: nTo,
						description: nDescription,
						linkPay: nLinkPay,
						action: action,
						image: image,
						connectedEventDates: connectedEventDates,
						imageName: imageName,
						removeImage: removeImage,
					})
				"
				class="btn btn-danger"
			>
				{{ secondButtonText }}
			</button>
			<button v-on:click="$emit('on-close')" class="btn btn-primary">
				Cancel
			</button>
		</div>
	</div>
</template>

<script>
	// Main JS (in UMD format)
	import moment from 'moment';
	import VueTimepicker from 'vue2-timepicker';
	import Datepicker from 'vuejs-datepicker';
	import imageCompression from 'browser-image-compression';

	// CSS
	import 'vue2-timepicker/dist/VueTimepicker.css';

	export default {
		created: function() {
			// console.log("EVENT IS HERE: ", this.event);
		},
		name: 'EditEvent',
		props: [
			'event',
			'action',
			'buttonText',
			'selectedDate',
			'selectedDateKey',
			'selectedDay',
			'baseUrl',
			'secondButtonText',
			'deleteEvent',
			'allEvents',
		],
		components: {
			VueTimepicker,
			Datepicker,
		},
		data() {
			return {
				id: this.event ? this.event._id : '',
				nName: this.event ? this.event.name : '',
				nFee: this.event ? this.event.fee : '',
				nFrom: this.event ? this.event.timeFrom : '',
				nTo: this.event ? this.event.timeTo : '',
				nDescription: this.event ? this.event.description : '',
				nLinkPay: this.event ? this.event.linkPay : '',
				repeatdate: null,
				image: this.event ? this.event.image : '',
				allDates: Object.keys(this.allEvents),
				removeImage: false,
				imageName: '', //NOTHING HERE - this is only for newly uploaded files //old: this.event && this.event.image ? this.event.image.name : "",
				imageUrl:
					this.event && this.event.image
						? this.baseUrl + '/eventimages/' + this.event.image.name
						: '',
				eventFormat: this.event ? this.event.eventFormat : 'dontrepeat',
				connectedEventDates: this.event ? this.event.connectedEventDates : [],
				disabledDates: {
					from: moment()
						.add(2, 'months')
						.toDate(),
					to: moment()
						.subtract(1, 'd')
						.toDate(),
				},
			};
		},
		methods: {
			onRepeatDayChange(event) {},
			onAddDate() {
				if (this.repeatdate) {
					let dateKey = moment(this.repeatdate).format('YYYY-MM-DD');

					if (!this.connectedEventDates.includes(dateKey)) {
						if (this.selectedDateKey != dateKey) {
							if (!this.allDates || this.allDates.length == 0) {
								this.connectedEventDates.push(dateKey);
							} else {
								if (!this.allDates.includes(dateKey)) {
									this.connectedEventDates.push(dateKey);
								} else {
									this.flash('This date is already being used.', 'warning', {
										timeout: 3000,
									});
								}
							}
						} else {
							this.flash('This date is already being used.', 'warning', {
								timeout: 3000,
							});
						}
					}
				}
			},
			getDateFromKey(dateKey) {
				if (dateKey) {
					let selectedDateMoment = moment(dateKey, 'YYYY-MM-DD');
					let date = moment(selectedDateMoment).format('ll');
					let day = moment(this.selectedDateMoment).format('dddd');
					return day + ', ' + date;
				}
			},
			closeRepeatedDate(dateKey) {
				if (this.action == 'add' || !this.allDates.includes(dateKey)) {
					this.connectedEventDates = this.connectedEventDates.filter(
						(value, index) => {
							if (value == dateKey) {
								return false;
							} else {
								return true;
							}
						}
					);
				} else {
					if (
						confirm(
							'Do you want to permanently delete this event (' +
								this.getDateFromKey(dateKey) +
								')? This page will refresh.'
						)
					) {
						this.deleteEvent(dateKey, '', true);
					}
				}
			},
			onDateSelected(date) {},
			async uploadImage(e) {
				let image = e.target.files[0];
				this.imageName = image.name;

				const options = {
					maxSizeMB: 0.45, // (default: Number.POSITIVE_INFINITY)
					maxWidthOrHeight: 500, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
				};

				let compressedImage = await imageCompression(image, options);
				console.log('image: ', compressedImage);
				const reader = new FileReader();
				reader.readAsDataURL(compressedImage);
				reader.onload = (e) => {
					this.image = e.target.result;
				};
			},
			isMilitaryTime(string) {
				return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
					string
				);
			},
			onRemoveImagePress() {
				this.removeImage = !this.removeImage;
			},
			isLongEnough(string, length, errorMessage) {
				let stringLength = string ? string.length : 0;
				if (typeof string === 'string') {
					if (string) {
						if (string.length >= length) {
							return '';
						}
					}
				}
				return length - stringLength + ' more characters';
			},
		},
	};
</script>

<style scoped>
	.form-check-label {
		margin-top: 1px;
	}
	.repeatedDate {
		display: inline-block;
		margin-right: 5px;
		margin-bottom: 10px;
		background-color: gray;
		padding: 5px;
		border-radius: 5px;
		color: white;
	}
	.add-date-button {
		height: 30px;
		padding: 5px;
		font-size: 13px;
		border-radius: 5px;
		margin-left: 0.5px;
	}

	.image {
		width: 200px;
		height: 200px;
	}
	.opacity {
		opacity: 0.5;
	}
	.image-container {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
	}
	.remove-image-button {
		width: 200px;
	}

	.sub-text {
		color: gray;
		font-size: 13px;
		width: 200px;
		text-align: center;
	}
</style>
