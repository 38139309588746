<template>
  <div
    :class="
      this.isClosed ||
      beer ||
      wine ||
      shot ||
      liquor ||
      whiskey ||
      cocktail ||
      happyText
        ? 'column-wrap success-column'
        : 'column-wrap error-column'
    "
  >
    <span class="card-icon fas fa-dollar-sign"> </span>
    <div style="display: flex; align-items: center">
      <label class="column-label"
        >Happy hour prices ({{
          day.charAt(0).toUpperCase() + day.substring(1, day.length)
        }})</label
      >
    </div>
    <div v-if="!isClosed">
      <div class="happy-container">
        <label class="first-label label" :for="'happy-beer' + day">Beer:</label>
        <div class="happy-hour-column">
          <input
            :value="beer"
            v-on:input="$emit('change-drink', $event, day, 'beer')"
            :id="'happy-beer' + day"
            type="number"
            class="form-control"
          />
          <span class="kr-text">kr</span>
        </div>
      </div>
      <div>
        <label class="label" :for="'happy-wine' + day">Wine:</label>
        <div class="happy-hour-column">
          <input
            v-on:input="$emit('change-drink', $event, day, 'wine')"
            :value="wine"
            :id="'happy-wine' + day"
            type="number"
            class="form-control"
          />
          <span class="kr-text">kr</span>
        </div>
      </div>
      <div>
        <label class="label" :for="'happy-shot' + day">Shot:</label>
        <div class="happy-hour-column">
          <input
            v-on:input="$emit('change-drink', $event, day, 'shot')"
            :value="shot"
            :id="'happy-shot' + day"
            type="number"
            class="form-control"
          />
          <span class="kr-text">kr</span>
        </div>
      </div>
      <div>
        <label class="label" :for="'happy-cocktail' + day">Cocktail:</label>
        <div class="happy-hour-column">
          <input
            v-on:input="$emit('change-drink', $event, day, 'cocktail')"
            :value="cocktail"
            :id="'cocktail' + day"
            type="number"
            class="form-control"
          /><span style="margin-left: 10px">kr</span>
        </div>
      </div>
      <div>
        <label class="label" :for="'happy-liquor' + day" title="Liquor"
          >Liquor:</label
        >
        <div class="happy-hour-column">
          <input
            v-on:input="$emit('change-drink', $event, day, 'liquor')"
            :value="liquor"
            :id="'happy-liquor' + day"
            type="number"
            class="form-control"
          />
          <span class="kr-text">kr</span>
        </div>
      </div>
      <div>
        <label class="label" :for="'happy-whiskey' + day">Whiskey:</label>
        <div class="happy-hour-column">
          <input
            v-on:input="$emit('change-drink', $event, day, 'whiskey')"
            :value="whiskey"
            :id="'happy-whiskey' + day"
            type="number"
            class="form-control"
          />
          <span class="kr-text">kr</span>
        </div>
      </div>

      <div v-if="!beer && !wine && !shot && !liquor && !whiskey && !cocktail">
        <label class="label" :for="'happy-text' + day">Happy hour text</label>
        <div class="happy-hour-column">
          <input
            v-on:input="$emit('change-drink', $event, day, 'happyText', true)"
            :value="happyText ? happyText : ''"
            :id="'happy-text' + day"
            maxlength="80"
            class="form-control"
          />
        </div>
      </div>

      <hr style="margin-bottom: 0px !important" />

      <div>
        <label
          style="color: gray; margin-top: 23px"
          :for="'happy-set-price' + day"
          >Copy from other day:</label
        >
        <select
          :id="'happy-set-price' + day"
          class="form-control special-select"
          v-on:change="$emit('on-happy-hour-day-change', $event, day)"
        >
          <option value>Select / Copy</option>
          <option value="monday">Set to same as Monday</option>
          <option value="tuesday">Set to same as Tuesday</option>
          <option value="wednesday">Set to same as Wednesday</option>
          <option value="thursday">Set to same as Thursday</option>
          <option value="friday">Set to same as Friday</option>
          <option value="saturday">Set to same as Saturday</option>
          <option value="sunday">Set to same as Sunday</option>
          <option style="font-weight: bold" value="all">
            Set these prices to all other days
          </option>
        </select>
      </div>
      <div
        v-if="
          !this.isClosed &&
          !beer &&
          !wine &&
          !shot &&
          !liquor &&
          !whiskey &&
          !cocktail &&
          !happyText
        "
      >
        <span style="margin-top: 5px" class="text-danger"
          >If you have listed your happy hour times, then you also have to list
          at least one price.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "beer",
    "wine",
    "shot",
    "liquor",
    "whiskey",
    "cocktail",
    "happyText",
    "day",
    "isClosed",
    "onHappyHourDayChange",
    "isHappyHourValid",
  ],
  methods: {
    keyUp(event, type) {
      this.$emit("change-drink", event, this.day, type);
      if (event.target.value && event.target.value.length > 0) {
        if (!this.isHappyHourValid) this.isHappyHourValid = true;
        return true;
      } else {
        //length is zero
        let day = this.day;
        let beerVal = $("#happy-beer" + day).val();
        let wineVal = $("#happy-wine" + day).val();
        let shotVal = $("#happy-shot" + day).val();
        let liquorVal = $("#happy-liquor" + day).val();
        let cocktailVal = $("#happy-cocktail" + day).val();
        let whiskeyVal = $("#happy-whiskey" + day).val();
        let happyTextVal = $("#happy-text" + day).val();
        if (
          beerVal ||
          wineVal ||
          shotVal ||
          liquorVal ||
          cocktailVal ||
          whiskeyVal ||
          happyTextVal
        ) {
          this.isHappyHourValid = true;
        } else {
          this.isHappyHourValid = false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.happy-container {
  margin-top: 15px;
}
label {
  margin-top: 5px;
  margin-bottom: 5px;
}
.first-label {
  margin-top: 0px !important;
}
.success-column {
  /* background-color: #c8dccb; */
  border: green 1px solid;
  background-color: white;
}

.error-column {
  background-color: white;
  border: red 1px solid;
  /* background-color: #f1cece; */
}
.kr-text {
  margin-left: 10px;
}
.happy-hour-column {
  display: flex;
  align-items: center;
}
</style>
