<template>
  <div>
    <h1>Register</h1>

    <input
      type="email"
      placeholder="email"
      v-model="email"
      name="email"
      id="email"
    />
    <input
      type="password"
      placeholder="password"
      v-model="password"
      name="password"
      id="password"
    />
    <p class="error-text">{{ errorText }}</p>
    <p class="success-text">{{ successText }}</p>
    <button @click="register">Register</button>
    <a href="/Register">Login</a>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
export default {
  data() {
    return {
      email: "",
      password: "",
      errorText: "",
      successText: ""
    };
  },
  methods: {
    async register() {
      const response = await AuthenticationService.register({
        email: this.email,
        password: this.password
      });
      if (response && response.data) {
        let data = response.data;
        if (data.success && data.message) {
          window.location.href = "" + window.location.origin + "/login";
          this.successText = data.message;
        } else {
          this.errorText = data.message;
        }
      } else {
        this.errorText =
          "The service is under maintenance. Please try again later. ";
      }
    }
  }
};
</script>

<style scoped>
.error-text {
  color: red;
}

.success-text {
  color: green;
}
</style>
