<template>
	<div>
		<div v-if="showBar" class="main-container-dash">
			<nav class="navbar navbar-expand-lg navbar-light bg-light">
				<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarTogglerDemo01"
					aria-controls="navbarTogglerDemo01"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbarTogglerDemo01">
					<a class="navbar-brand" href="">Barhopp</a>
					<ul class="navbar-nav mr-auto mt-2 mt-lg-0">
						<a
							:class="
								!isActive
									? 'nav-item nav-link text-danger'
									: 'nav-item nav-link'
							"
							@click="scrollToItem('activation-panel')"
							href="javascript:void(0)"
							>Activation</a
						>
						<a
							:class="
								!isDescription
									? 'nav-item nav-link text-danger'
									: 'nav-item nav-link'
							"
							@click="scrollToItem('details-panel')"
							href="javascript:void(0)"
							>Details</a
						>
						<a
							:class="
								!isLocation
									? 'nav-item nav-link text-danger'
									: 'nav-item nav-link'
							"
							@click="scrollToItem('location-panel')"
							href="javascript:void(0)"
							>Location</a
						>
						<a
							:class="
								!isOpening
									? 'nav-item nav-link text-danger'
									: 'nav-item nav-link'
							"
							@click="scrollToItem('opening-panel')"
							href="javascript:void(0)"
							>Opening hours & happy hour details</a
						>
						<a
							class="nav-item nav-link"
							@click="scrollToItem('events-panel')"
							href="javascript:void(0)"
							>Events</a
						>
						<a
							class="nav-item nav-link"
							@click="scrollToItem('drinks-panel')"
							href="javascript:void(0)"
							>Drinks</a
						>
						<a
							:class="
								!isPicture
									? 'nav-item nav-link text-danger'
									: 'nav-item nav-link'
							"
							@click="scrollToItem('images-panel')"
							href="javascript:void(0)"
							>Images</a
						>
					</ul>
					<div class="form-inline my-2 my-lg-0">
						<button
							@click="logout()"
							class="btn btn-outline-danger my-2 my-sm-0"
							type="submit"
						>
							Logout
						</button>
					</div>
				</div>
			</nav>

			<div v-if="!this.showLoad">
				<div
					style="width: 100%; z-index: 1"
					v-if="!this.isActive"
					class="not-active-banner"
				>
					Bar is not active
				</div>
			</div>
			<div class="header-image header-image-cover text-center">
				<h1 class="first-header">{{ barName }}</h1>
				<!-- <p class="second-header">Free advertising platform for bars</p> -->
				<div>
					<button
						@click="goToMain()"
						class="btn btn-primary"
						style="width: 200px"
					>
						Back to main page
					</button>
				</div>
			</div>
			<div class="panel-cards">
				<div class="show-card show-card-one">
					<span class="fas fa-calendar-day icon"></span>
					<p class="text">List your events here to draw in more customers!</p>
				</div>
				<div class="show-card show-card-two">
					<span class="fas fa-beer icon"></span>
					<p class="text">
						Draw in customers with your exciting happy hour deals!
					</p>
				</div>
				<div class="show-card show-card-three">
					<span class="fas fa-clock icon"></span>
					<p class="text">Are you still open? Great, let people know!</p>
				</div>
			</div>

			<flash-message class="flash"></flash-message>

			<div
				v-bind:class="{
					'dashboard-container-loading': showLoad,
					'container-lg': true,
				}"
			>
				<div class="row" id="activation-panel">
					<div class="col">
						<div class="card" style="min-height: 100px">
							<div class="card-body">
								<div
									style="
                    display: flex;
                    justify-content: space-between;
                    margin-top: 18px;
                  "
								>
									<h5
										style="
                      display: flex;
                      align-items: center;
                      margin-bottom: 0px;
                    "
										class="card-title"
									>
										Show bar
									</h5>
									<a href="javascript:void(0)" @click="changeBarActivation">
										<toggle-button
											:value="this.isActive"
											color="#5b7ff9"
											:sync="true"
											:height="40"
											:width="70"
											:labels="true"
										/>
									</a>
								</div>

								<div
									v-if="
										!this.isDescription ||
											!this.isOpening ||
											!this.isLocation ||
											!this.isPicture
									"
								>
									<p
										class="error-text"
										style="font-size: 19px; font-weight: 600"
									>
										Missing information:
									</p>
									<p v-if="!this.isDescription" class="error-text">
										<i class="error-text fas fa-exclamation-circle"></i>
										The description cannot be empty.
									</p>
									<p v-if="!this.isOpening" class="error-text">
										<i class="error-text fas fa-exclamation-circle"></i>
										Correct opening/closing times and happy hour times must be
										set.
									</p>
									<p v-if="!this.isLocation" class="error-text">
										<i class="error-text fas fa-exclamation-circle"></i>
										Location must be present.
									</p>
									<p v-if="!this.isPicture" class="error-text">
										<i class="error-text fas fa-exclamation-circle"></i>
										At least one picture of the bar must be available.
									</p>
								</div>
								<div v-else>
									<p
										class="success-text"
										style="font-size: 19px; font-weight: 600"
									></p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div class="row" id="details-panel">
						<div class="col-md-6">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">About</h5>
									<label for="description">Description:</label>
									<textarea
										id="description"
										v-model="description"
										maxlength="1000"
										class="form-control"
									></textarea>
									<small class="form-text text-muted"
										>A short description of this bar.</small
									>
									<label style="margin-top: 8px">Dress code</label>
									<input
										maxlength="50"
										id="dresscode"
										v-model="dresscode"
										class="form-control"
									/>
									<small class="form-text text-muted">
										Don't lose customers because they're not appropriately
										dressed.
									</small>
								</div>

								<button
									@click="saveDescription()"
									class="btn btn-primary save-btn"
								>
									Save about
								</button>
							</div>
						</div>

						<div class="col-md-6 feature-container">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">Features</h5>
									<label
										>Please select the features that are relevant to this
										bar/place:</label
									>
									<div style="display: flex">
										<select
											class="form-control"
											@change="onChangeType($event.target)"
											id="typeOfBarSelect"
										>
											<option value="none">Select relevant items</option>

											<option
												v-for="item in barTypesAvailable"
												:key="item.index"
											>
												{{ item }}
											</option>
										</select>
										<button
											class="btn btn-info"
											style="width: 150px"
											@click="onAddType"
										>
											Add
										</button>
									</div>
									<div style="display: flex; flex-wrap: wrap">
										<div
											v-for="item in barTypes"
											:key="item.index"
											style="margin-right: 7px"
										>
											<a
												@click="removeType(item)"
												style="
                          text-decoration: none;
                          color: white;
                          cursor: pointer;
                        "
												class="badge badge-info"
											>
												{{ item }}
												<span
													class="badge-item fas fa-times"
													style="
                            color: white;
                            padding-left: 3px;
                            cursor: pointer;
                            width: 20px;
                          "
													href="javascript:void(0)"
												></span>
											</a>
										</div>
									</div>
									<div>
										<label style="margin-top: 20px" for="typeOfMainBar"
											>Please choose <b>one feature</b> that describes this
											bar/place the most:</label
										>

										<div style="display: flex">
											<select
												:selected="genre"
												class="form-control"
												@change="onChangeGenre($event.target)"
												id="typeOfMainBar"
											>
												<option value="">Select the most relevant item</option>
												<option
													v-for="item in genres"
													:value="item"
													:selected="item == genre"
													:key="item.index"
												>
													{{ item }}
												</option>
											</select>
										</div>
									</div>
									<div>
										<input
											style="margin-right: 5px;"
											type="checkbox"
											id="isFoodService"
											v-model="isFoodService"
										/>
										<label for="isFoodService" style="margin-top: 8px"
											>Bar has food service</label
										>
									</div>
									<div v-if="isFoodService">
										<label style="margin-top: 8px">Food description</label>
										<input
											maxlength="500"
											id="foodDescription"
											v-model="foodDescription"
											class="form-control"
										/>
										<small data-v-aae30ed8="" class="form-text text-muted">
											A short description about the food style of the place.
										</small>
									</div>
								</div>
								<button @click="saveFeatures" class="btn btn-primary save-btn">
									Save features
								</button>
							</div>
						</div>
					</div>

					<div class="row" v-if="isAdmin" id="location-panel">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">Links</h5>
									<div class="form-row">
										<div class="form-group col-md-6">
											<label for="websiteLink">Website</label>
											<input
												v-model="websiteLink"
												placeholder="https://..."
												id="websiteLink"
												class="form-control"
											/>
										</div>
										<div class="form-group col-md-6">
											<label for="facebookLink">Facebook link</label>
											<input
												placeholder="https://..."
												v-model="facebookLink"
												id="facebookLink"
												class="form-control"
											/>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-6">
											<label for="instagramLink">Instagram link</label>
											<input
												id="instagramLink"
												placeholder="https://..."
												v-model="instagramLink"
												class="form-control"
											/>
										</div>
										<div class="form-group col-md-6">
											<label for="drinkMenu">Drink menu</label>
											<input
												id="drinkMenu"
												placeholder="https://..."
												v-model="drinkMenu"
												class="form-control"
											/>
										</div>
									</div>
									<div class="form-row">
										<label for="foodMenu">Food menu</label>
										<input
											id="foodMenu"
											placeholder="https://..."
											v-model="foodMenu"
											class="form-control"
										/>
									</div>
								</div>
								<button @click="saveLinks" class="btn btn-primary save-btn">
									Save links
								</button>
							</div>
						</div>
					</div>

					<div class="row" v-if="!isLocationLocked" id="location-panel">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">Location</h5>
									<Location v-on:save-location="saveLocation" :long="long" />
									<div class="form-row">
										<div class="form-group col-md-3">
											<label for="lat">Latitude (first number)</label>
											<input v-model="lat" id="lat" class="form-control" />
										</div>
										<div class="form-group col-md-3">
											<label for="long">Longitude</label>
											<input v-model="long" id="long" class="form-control" />
										</div>
										<div class="form-group col-md-6">
											<label for="address">Address</label>
											<input
												v-model="address"
												id="address"
												class="form-control"
											/>
										</div>
									</div>
								</div>
								<button @click="saveLocation" class="btn btn-primary save-btn">
									Save location
								</button>
							</div>
						</div>
					</div>

					<button
						style="text-align: center"
						v-if="isAdmin"
						@click="lockLocation"
						class="btn btn-warning save-btn"
					>
						{{ isLocationLocked ? 'Unlock' : 'Lock' }} location
					</button>

					<div v-if="!showLoad" class="row" id="events-panel">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">Bar events</h5>
									<Events
										:days="days"
										v-on:on-manage-event="onManageEvent"
										v-on:on-delete-event="onDeleteEvent"
										:deleteEvent="onDeleteEvent"
										:events="events"
										:baseUrl="baseUrl"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="row" id="drinks-panel">
						<div class="col">
							<div class="card">
								<div class="card-body" style="overflow-x: scroll">
									<h5 class="card-title">Drinks at bar / Happy hour drinks</h5>

									<table class="table table-striped">
										<thead>
											<tr>
												<th scope="col">No</th>
												<th scope="col">Type</th>
												<th scope="col">Name</th>
												<th scope="col">Regular price(kr)</th>
												<th scope="col">Happy hour price(kr)</th>
												<!-- <th scope="col">Days available on happy hour</th> -->
												<th scope="col">Short information</th>
												<th scope="col"></th>
												<th scope="col"></th>
											</tr>
										</thead>

										<tbody>
											<tr v-for="(item, index) in drinks" :key="index">
												<td>{{ index + 1 }}</td>
												<td>{{ item.type ? item.type : '' }}</td>
												<td>{{ item.name ? item.name : '' }}</td>
												<td>{{ item.price ? item.price : '' }}</td>
												<td>{{ item.happyPrice ? item.happyPrice : '' }}</td>
												<!-- <td>{{ item.daysDrinks ? item.daysDrinks.join(", ") : "" }}</td> -->
												<td>{{ item.description ? item.description : '' }}</td>
												<td>
													<a
														href="javascript:void(0)"
														@click="editDrink(index, item)"
														>Edit</a
													>
												</td>
												<td>
													<a
														href="javascript:void(0)"
														@click="deleteDrink(index, item._id)"
														>Delete</a
													>
												</td>
											</tr>
										</tbody>
									</table>

									<button
										type="button"
										class="btn btn-primary"
										data-toggle="modal"
										data-target="#addDrinkModal"
									>
										Add drink
									</button>
								</div>
								<!-- <button
                @click="onShowAddDrink(true)"
                class="btn btn-primary save-btn"
              >
                Save changes
              </button>-->
							</div>
						</div>
					</div>

					<div
						class="modal fade"
						id="editDrinkModal"
						tabindex="-1"
						data-backdrop="static"
						role="dialog"
						aria-labelledby="editDrinkModalTitle"
						aria-hidden="true"
					>
						<div class="modal-dialog modal-dialog" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="exampleModalLongTitle">
										Edit drink number {{ itemIndex + 1 }}
									</h5>
								</div>
								<div class="modal-body">
									<div v-if="this.editIndex !== null && this.editIndex > -1">
										<EditDrink
											title="Edit happy hour drink"
											btnText="Confirm changes"
											cancelBtnText="Cancel"
											compId="edit"
											:itemIndex="itemIndex"
											:drink="drink"
											:name="name"
											:type="type"
											:ml="ml"
											:drinkId="drinkId"
											:from="from"
											:to="to"
											:price="price"
											:happyPrice="happyPrice"
											:days="days"
											:alc="alc"
											:description="information"
											v-on:on-close="onCloseEdit"
											v-on:on-handle-drink="onHandleDrink"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="this.days !== null" id="opening-panel">
						<OpeningTimes
							closedText="Bar is closed on"
							type="opening"
							title="Opening times & happy hour times"
							buttonText="Save"
							v-on:on-handle-opening="saveOpeningTimes"
							:times="this.days"
						/>
					</div>

					<div
						class="modal fade"
						id="addDrinkModal"
						tabindex="-1"
						data-backdrop="static"
						role="dialog"
						aria-labelledby="addDrinkModalTitle"
						aria-hidden="true"
					>
						<div class="modal-dialog modal-dialog" role="document">
							<div class="modal-content">
								<div class="modal-header">
									<h5 class="modal-title" id="exampleModalLongTitle">
										Add drink
									</h5>
								</div>
								<div class="modal-body">
									<EditDrink
										title="Add a happy hour drink"
										btnText="Add drink and save"
										cancelBtnText="Cancel"
										compId="add"
										name
										type
										ml
										happyPrice
										from
										to
										price
										:days="[]"
										alc
										:description="information"
										:itemIndex="-1"
										v-on:on-close="onShowAddDrink(false)"
										v-on:on-handle-drink="onHandleDrink"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="row" id="images-panel">
						<div class="col">
							<div class="card">
								<div class="card-body">
									<h5 class="card-title">Images</h5>
									<div
										style="
                      max-width: 800px;
                      margin: auto;
                      background-color: lightgray;
                      padding: 10px;
                      border-radius: 20px;
                      margin-bottom: 25px;
                    "
									>
										<p class="text-center" style="text-decoration: underline">
											Please read the rules before posting an image. Breaking
											these rules can result in a ban from using this service.
										</p>
										<h3 style="font-size: 17px; font-weight: bold">
											Image notes:
										</h3>

										<p>1. Only post photos of this bar/place.</p>
										<p>
											2. You understand that the first image (image furthest to
											the left here below) is used as a profile photo for the
											bar.
										</p>
										<p>
											3. Do not post inappropriate photos. For instance, photos
											including pornographic material.
										</p>
										<p>4. Only post pictures you are allowed to use.</p>
										<!-- <p>
                      Please only post photos that are less than 1mb. If your
                      image is bigger than that, you can reduce the quality of
                      the image with software such as Paint or websites like
                      this one: https://www.reduceimages.com/
                    </p> -->
										<p>
											Tip: Having your first picture of the bar from the outside
											makes it easier for people to find.
										</p>
									</div>

									<div v-if="images && images.length > 1">
										<p class="text-center">
											<span class="fas fa-expand-arrows-alt"></span>
											Hold to drag.
										</p>
										<p class="text-center">
											<span class="fas fa-images"></span>

											Click for slideshow.
										</p>
									</div>

									<Images
										v-on:delete-image="onDeleteImage"
										:onImagesChange="onImagesChange"
										:images="images"
										v-on:on-file-selected="onFileSelected"
									/>
								</div>
								<div>
									<button
										v-if="showSaveImagesButton"
										@click="saveImages"
										class="btn btn-primary save-btn"
									>
										Save changes
									</button>
									<div v-if="!images || images.length < 5">
										<label for="file-upload" style="margin-left: 20px"
											>Add image</label
										>
										<input
											id="file-upload"
											style="margin-left: 20px; width: 90%"
											class="form-control-file"
											@change="uploadImage"
											type="file"
											accept="image/jpeg, image/jpg, image/png"
										/>
										<button
											@click="onUploadConfirm"
											class="btn btn-primary save-btn"
										>
											Add image
										</button>
									</div>
									<div class="save-btn" v-else>
										<p>You have uploaded the maximum number of images</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="padding-top: 30px; padding-bottom: 30px">
						<p class="text-center" style="font-weight: bold; font-size: 16px">
							Disclaimer
						</p>
						<p class="text-center">
							If the information disclosed on this page is incorrect or
							fraudulent, you may loose the right to edit this page.
						</p>
						<p class="text-center">
							We might log malicious or fraudulent requests and data to keep you
							and our users on the platform safe.
						</p>
					</div>
				</div>
			</div>
			<div v-bind:class="{ 'dashboard-container-loading': !showLoad }">
				<div
					style="margin: auto; text-align: center, marginBottom: 200px;"
					class="loader"
				></div>
				<p style="text-align: center; margin-top: 10px">{{ this.loadText }}</p>
			</div>
		</div>
	</div>
</template>

<script>
	import AuthenticationService from '@/services/AuthenticationService';
	import BarService from '@/services/BarService';
	import EditDrink from './EditDrink';
	import OpeningTimes from './OpeningTimes';
	import Images from './Images';
	import Events from './Events';
	import Location from './Location';
	import draggable from 'vuedraggable';
	import { ToggleButton } from 'vue-js-toggle-button';
	import Vue from 'vue';
	import imageCompression from 'browser-image-compression';

	Vue.component('ToggleButton', ToggleButton);
	// Main JS (in UMD format)
	// CSS
	export default {
		components: {
			EditDrink,
			Location,
			OpeningTimes,
			Images,
			Events,
			draggable,
		},
		mounted() {
			this.getData();
		},
		data() {
			return {
				barName: '',
				barId: null,
				description: '',
				long: null,
				lat: null,
				address: '',
				dresscode: '',
				barTypes: [],
				barTypesAvailable: [],
				foodDescription: '',
				genres: [],
				genre: '',
				editIndex: null,
				selectedFile: null,
				showAddDrink: false,
				drinks: [],
				days: null,
				images: [],
				pause: false,
				events: {},
				baseUrl: '',
				isFoodService: false,
				file: null,
				image: null,
				selectedType: '',
				showSaveImagesButton: false,
				showLoad: true,
				isAdmin: false,
				isActive: false,
				isLocationLocked: false,
				isLocation: false,
				isDescription: false,
				isOpening: false,
				isPicture: false,
				loadText: 'Loading your bar',
				showBar: false,
				facebookLink: '',
				instagramLink: '',
				websiteLink: '',
				foodMenu: '',
				drinkMenu: '',
			};
		},
		computed: {
			// a computed getter
			name: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].name;
			},
			type: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].type;
			},
			alc: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].alc;
			},
			ml: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].ml;
			},
			from: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].from;
			},
			to: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].to;
			},
			price: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].price;
			},
			happyPrice: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].happyPrice;
			},
			daysDrinks: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].days;
			},
			information: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex].description;
			},
			drinkId: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex]._id;
			},
			drink: function() {
				if (!this.editIndex && this.editIndex !== 0) return '';
				return this.drinks[this.editIndex];
			},
			itemIndex: function() {
				return this.editIndex;
			},
		},
		methods: {
			//-- HANDLE TYPES
			async getData() {
				let isSuccess = false;
				this.showLoad = true;
				let id = this.$route.params.id;
				this.barId = this.$route.params.id;
				if (id) {
					const response = await AuthenticationService.getBarData({
						id: id,
					});

					//REDIRECT IF NOT AUTHENTICATED
					if (
						response &&
						response.data &&
						response.data.message.includes('not authenticated')
					) {
						this.$router.push(`/panel`);
					}

					//HANDLE RESPONSE IF AUTHENTICATED
					if (response && response.data && response.data.isSuccess) {
						this.isAdmin = response.data.isAdmin;
						this.barTypes = response.data.featuresDisplay
							? response.data.featuresDisplay
							: [];
						this.barTypesAvailable = response.data.allFeatures
							? response.data.allFeatures.filter((value, index) => {
									return !this.barTypes.includes(value);
							  })
							: [];
						this.genres = response.data.genres;
						this.genre = response.data.genre;
						this.baseUrl = response.data.baseUrl;
						let barData = response.data['data'];
						if (barData) {
							this.barName = barData.name;
							this.description = barData.description;
							this.lat = barData.lat ? barData.lat.toString() : null;
							this.dresscode = barData.dresscode ? barData.dresscode : null;
							this.long = barData.long ? barData.long.toString() : null;
							this.drinks = barData.happyDrinks;
							this.days = barData.days;
							this.isLocationLocked = barData.isLocationLocked;
							// this.images = barData.images;
							this.images = response.data.images;
							this.events = barData.events;
							this.address = barData.address;
							this.foodDescription = barData.foodDescription;
							this.lat = barData.lat;
							this.long = barData.long;
							this.isActive = barData.isActive;
							this.isFoodService = barData.isFoodService;
							this.isLocation = response.data.isLocation;
							this.genre = barData.genre;
							this.isDescription = response.data.isDescription;
							this.isOpening = response.data.isOpening;
							this.isPicture = response.data.isPicture;

							//Links
							(this.facebookLink = barData.facebookLink),
								(this.instagramLink = barData.instagramLink),
								(this.websiteLink = barData.websiteLink),
								(this.foodMenu = barData.foodMenu),
								(this.drinkMenu = barData.drinkMenu),
								(this.showBar = true);
							isSuccess = true;
						}
					}
				}
				this.showLoad = false;
				this.refreshSessionPeriodically();
			},
			goToMain() {
				this.$router.push(`/panel`);
			},
			scrollToItem(id) {
				var scrollDiv = document.getElementById(id).offsetTop;
				window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
			},
			async changeBarActivation() {
				if (!this.pause) {
					this.pause = true;
					let response = await BarService.changeBarActivation({
						id: this.barId,
					});
					this.handleResponse(response.data);
					setTimeout(function() {
						this.pause = false;
					}, 400);
				}
			},
			async refreshSessionPeriodically() {
				setInterval(async () => {
					let response = await BarService.isAuthed({});
					if (response && response.data && !response.data.isSuccess) {
						this.goToMain();
					}
				}, 1000000);
			},
			onAddType(e) {
				e.preventDefault();
				let type = this.selectedType;
				if (type) {
					if (this.barTypes.indexOf(type) === -1) {
						this.barTypes.push(type);
						this.barTypesAvailable = this.barTypesAvailable.filter(
							(value) => value !== type
						);
						$('#typeOfBarSelect').val('none');
						this.selectedType = null;
					}
				}
			},
			onSetTypes() {},
			onChangeType(type) {
				this.selectedType = type.value;
			},
			onChangeGenre(genre) {
				this.genre = genre.value;
			},
			removeType(type) {
				if (type != 'none') {
					if (
						confirm(
							'Do you want to remove ' +
								type +
								"? You will have to press 'Save features' to save your changes."
						)
					) {
						this.barTypes = this.barTypes.filter((value) => value !== type);
						this.barTypesAvailable.push(type);
					}
				}
			},
			//-- HANDLE DRINKS
			editDrink(index, item) {
				if (this.editIndex !== null) {
					this.onCloseEdit();
				}
				setTimeout(() => {
					this.editIndex = index;
					$('#editDrinkModal').modal('show');
				}, 500);
			},
			onCloseEdit(shouldShow) {
				this.editIndex = null;
				$('#editDrinkModal').modal('hide');
			},
			onShowAddDrink(shouldShow) {
				$('#addDrinkModal').modal('hide');
			},
			deleteDrink(index, drinkId) {
				if (confirm('Are you sure you want to delete this drink?')) {
					this.deleteHappyDrink(index, drinkId);
				}
			},
			onHandleDrink(index, data) {
				if (index === -1 || index === '-1') {
					this.addHappyDrink(data);
					$('#addDrinkModal').modal('hide');
				} else if (index > -1) {
					this.editHappyDrink(index, data);
					this.onCloseEdit();
				}
			},
			//-- IMAGES
			onFileSelected(event) {
				this.selectedFile = event.target.files[0];
			},
			onImagesChange(e) {
				this.showSaveImagesButton = true;
				this.flash(
					"Press 'Save changes' below the images to save the order.",
					'info',
					{ timeout: 3000 }
				);
			},
			async saveImages() {
				let imageIds = this.images.map((value, index) => {
					return value.id;
				});

				const response = await BarService.saveImages({
					id: this.barId,
					imageIds: imageIds,
				});
				this.handleResponse(response.data);
			},
			async uploadImage(e) {
				let image = e.target.files[0];
				const options = {
					maxSizeMB: 0.5, // (default: Number.POSITIVE_INFINITY)
					maxWidthOrHeight: 500, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
				};

				image = await imageCompression(image, options);

				if (image && image.size < 502000) {
					this.imageName = image.name;
					this.selectedFile = image;
					const reader = new FileReader();
					reader.readAsDataURL(image);
					reader.onload = (e) => {
						this.image = e.target.result;
					};
				} else {
					this.flash('File has to be smaller than 2mb', 'error');
				}
			},
			async onUploadConfirm() {
				const response = await BarService.uploadImage({
					id: this.barId,
					image: this.image,
					name: this.imageName,
				});
				this.handleResponse(response.data);
			},
			async onDeleteImage(id) {
				if (confirm('Do you want to delete this image? ')) {
					const response = await BarService.deleteImage({
						id: this.barId,
						imageId: id,
					});
					this.handleResponse(response.data);
				}
			},
			async saveDescription() {
				if (this.description && this.description.length <= 1000) {
					const response = await BarService.saveDescription({
						description: this.description,
						dresscode: this.dresscode,
						id: this.barId,
					});
					this.handleResponse(response.data);
				} else {
					this.flash('Description is too long', 'error');
				}
			},
			async saveLinks() {
				const response = await BarService.saveLinks({
					id: this.barId,
					facebookLink: this.facebookLink,
					instagramLink: this.instagramLink,
					websiteLink: this.websiteLink,
					foodMenu: this.foodMenu,
					drinkMenu: this.drinkMenu,
				});
				this.handleResponse(response.data);
			},
			async saveFeatures() {
				const response = await BarService.saveFeatures({
					features: this.barTypes,
					genre: this.genre,
					isFoodService: this.isFoodService,
					foodDescription: this.foodDescription,
					id: this.barId,
				});
				this.handleResponse(response.data);
			},
			async lockLocation() {
				if (this.lat && this.long && this.address) {
					const response = await BarService.lockLocation({
						id: this.barId,
					});
					this.handleResponse(response.data);
				}
			},
			async saveLocation() {
				if (this.lat && this.long && this.address) {
					const response = await BarService.saveLocation({
						lat: this.lat,
						long: this.long,
						address: this.address,
						id: this.barId,
					});
					this.handleResponse(response.data);
				} else {
					this.flash(
						'You have to fill in latitude, longitude and address',
						'error'
					);
				}
			},
			async addHappyDrink(data) {
				const response = await BarService.addHappyDrink({
					id: this.barId,
					data: data,
				});

				this.handleResponse(response.data);
			},
			async editHappyDrink(index, data) {
				const response = await BarService.editHappyDrink({
					id: this.barId,
					data: data,
					index: index,
				});
				this.handleResponse(response.data);
			},
			async deleteHappyDrink(index, drinkId) {
				const response = await BarService.deleteHappyDrink({
					id: this.barId,
					drinkId: drinkId,
					drinkIndex: index,
				});

				this.handleResponse(response.data);
			},
			async saveOpeningTimes(data) {
				const response = await BarService.updateTimes({
					data: data,
					id: this.barId,
				});

				this.handleResponse(response.data);
			},
			async onManageEvent(
				id,
				eventData,
				selectedDateKey, //selected key here
				selectedDay, //day that is selected
				selectedDate //human readable date
				// name,
				// fee,
				// from,
				// to,
				// description,
				// linkPay,
				// action,
				// image,
				// connectedEventDates,
				// imageName,
				// saveRepeatedEvents,
				// removeImage
			) {
				let image = eventData.image;
				if (image) {
				}

				let data = {
					name: eventData.name,
					fee: eventData.fee,
					timeFrom: eventData.from,
					timeTo: eventData.to,
					description: eventData.description,
					linkPay: eventData.linkPay,
					image: image,
					connectedEventDates: eventData.connectedEventDates,
					imageName: eventData.imageName,
					removeImage: eventData.removeImage,
				};

				const response = await BarService.addEvent({
					eventId: id,
					action: eventData.action,
					data: data,
					selectedDateKey: selectedDateKey,
					day: selectedDay,
					id: this.barId,
				});

				this.handleResponse(response.data);
			},
			async onDeleteEvent(dateKey, day = '', skipAlert = false, type = 'one') {
				if (skipAlert || true) {
					const response = await BarService.deleteEvent({
						dateKey: dateKey,
						day: day,
						id: this.barId,
						type: type,
					});
					this.handleResponse(response.data);
				}
			},
			async logout() {
				const response = await AuthenticationService.logout({
					id: this.barId,
				});
				this.handleResponse(response.data);
			},
			handleResponse(response, timeout = 1000) {
				if (response.isSuccess) {
					this.showLoad = true;
					this.loadText = 'Saving changes';
					this.flash(response.message, 'success', {
						timeout: timeout,
						beforeDestroy() {
							window.location.reload();
						},
					});
				} else {
					this.flash(response.message, 'error');
					if(response.message.includes('not authenticated')) {
						this.$router.push(`/#/panel`);
					}
				}
			},
		},
	};
</script>

<style scoped>
	.main-container-dash {
		background-color: #f8f9fa !important;
		width: 100%;
		min-height: 100vh;
		/* padding-bottom: 400px; */
		/* height: 100vh; */
	}
	.error-text {
		color: red;
	}

	.success-text {
		color: green;
	}
	.first-header {
		font-weight: 800;
		font-size: 60px;
		text-align: center;
		color: white;
		padding-top: 40px;
	}
	.second-header {
		text-align: center;
		font-weight: 600;
		color: white;
	}
	.table-input {
		margin: 0px;
		padding: 0px;
	}
	.table-td-input {
		padding: 0px !important;
	}
	.small-table-input {
		width: 100%;
	}
	.checkbox-line {
		display: flex;
		align-items: center;
		margin-right: 20px;
	}
	.card {
		min-height: 250px;
		height: 100%;
		border-radius: 20px;
	}
	.card-space {
		margin-top: 30px;
	}
	.row {
		margin-top: 30px;
	}
	.badge-item {
		color: black;
	}
	.show-card {
		width: 200px;
		height: 300px;
		border-radius: 20px;
		background-color: yellow;
		margin: 20px;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.show-card > .text {
		color: white;
		margin-top: 50px;
		margin-left: 15px;
		margin-right: 15px;
		font-weight: 600;
		font-size: 15px;
	}
	.show-card-one {
		background-color: #fdb6b4;
	}
	.show-card-two {
		background-color: #5b7ff9;
		margin-top: -5px;
	}
	.show-card-three {
		background-color: #8773d8;
	}
	.icon {
		color: white;
		font-size: 80px;
		margin-top: 20px;
	}
	.dashboard-container-loading {
		display: none;
	}

	.vue-js-switch .v-switch-label.v-right {
		right: 3px !important;
	}

	.active-banner {
		width: 100%;
		height: 30px;
		background-color: green;
		color: white;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.not-active-banner {
		width: 100%;
		height: 30px;
		background-color: red;
		color: white;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.panel-cards {
		display: flex;
		justify-content: center;
		margin-top: -70px;
	}

	@media screen and (max-width: 768px) {
		.feature-container {
			margin-top: 30px;
		}
	}

	@media screen and (max-width: 600px) {
		.panel-cards {
			display: none;
		}
	}
</style>
