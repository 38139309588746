import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home";
import Register from "@/components/Register";
import Login from "@/components/Login";
import Forgot from "@/components/Forgot";
import Dashboard from "@/components/Dashboard";
import Welcome from "@/components/Welcome";
import Main from "@/components/Main";
import Terms from "@/components/Terms";
import Privacy from "@/components/Privacy";
import PrivacyWeb from "@/components/PrivacyWeb";
import TermsWeb from "@/components/TermsWeb";
import Admin from "@/components/Admin";
import ConfirmImages from "@/components/ConfirmImages";
import ResetTwoFactor from "@/components/ResetTwoFactor";

Vue.use(Router);

export default new Router({
  routes: [
    // {
    //   path: "/",
    //   name: "Main",
    //   component: Main
    // },
    {
      path: "/",
      name: "Welcome",
      component: Welcome
    }, 
    {
      path: "/panel",
      name: "Home",
      component: Home
    },
    {
      path: "/terms",
      name: "Terms",
      component: Terms
    },
    {
      path: "/privacy",
      name: "Privacy",
      component: Privacy
    },
    {
      path: "/privacyweb",
      name: "PrivacyWeb",
      component: PrivacyWeb
    },
    {
      path: "/termsweb",
      name: "TermsWeb",
      component: TermsWeb
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/resetpassword/:token",
      name: "resetpassword",
      component: Forgot
    },
    {
      path: "/dashboard/:id",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/bar44",
      name: "bar44",
      component: Admin
    },
    {
      path: "/confirmimages",
      name: "confirmimages",
      component: ConfirmImages
    },
    {
      path: "/resettwofaconfirmation/:token",
      name: "resettwofaconfirmation",
      component: ResetTwoFactor
    },
    {
      path: "/getImage",
      name: "getImage",
      component: Image
    }
  ]
});
