<template>
  <div>
    <div v-if="isAllowedToSee">
      <h4>Bars</h4>
      <form>
        <label>Create bar:</label>
        <input placeholder="Bar name" id="barname" v-model="barname" />
        <button @click="createBar">Create</button>
      </form>
      <hr />

      <select @change="onBarChange">
        <option>Select bar</option>
        <option
          class="form-control"
          v-for="bar in bars"
          :key="bar.index"
          :value="bar._id"
        >
          {{ bar.name }}
        </option>
      </select>
      <button @click="this.goToBar">Go to bar</button>
      <button @click="this.getBarDetails">Get details</button>
      <button @click="this.deleteBar">Delete this bar</button>
      <button @click="this.deactivateBar">Deactivate this bar</button>
      <hr />
      <h4>Users</h4>
      <select @change="onUserChange">
        <option>Select bar</option>
        <option
          class="form-control"
          v-for="user in users"
          :key="user.index"
          :value="user._id"
        >
          {{ user.name }} - {{ user.email }}
        </option>
      </select>
      <button @click="getUserDetails">Get details</button>
      <button @click="makeUserAdmin">
        Make user admin of {{ selectedBarName }}
      </button>
      <button @click="removeUserAdmin">
        Remove user as admin of {{ selectedBarName }}
      </button>
      <!-- <multiselect
      v-model="selectedUserId"
      :close-on-select="true"
      :options="newUsers.name"
      :values="newUsers.id"
    >
    </multiselect>-->
      <div>
        {{ userInformation }}
      </div>
      <hr />
      <div>
        <a target="_blank" href="/#/confirmimages">Confirm images</a>
      </div>
      <SpecialEvents :specialEvents="this.specialEvents" />

      <div style="margin-top: 100px">
        <h2>Expo notification</h2>
        <input v-model="expoTitle" placeholder="Expo title" />
        <input v-model="expoBody" placeholder="Expo body" />
        <input v-model="expoJson" placeholder="Expo json" />
        <button @click="sendNotification()">Send push notification</button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import Multiselect from "vue-multiselect";
import SpecialEvents from "./SpecialEvents";
import BarService from '@/services/BarService';

export default {
  components: {
    Multiselect,
    SpecialEvents,
  },
  async mounted() {
    await this.getContent();
  },
  data() {
    return {
      barname: null,
      bars: null,
      users: null,
      selectedBarId: null,
      selectedBarName: null,
      selectedUserId: null,
      isAllowedToSee: false,
      specialEvents: [],
      userInformation: "",
      expoTitle: "",
      expoBody: "",
      expoJson: null,
    };
  },
  methods: {
    async getContent() {
      const response = await AuthenticationService.getAdContent();
      console.log("response is getContent: ", response);
      if (response && response.data && response.data.isSuccess) {
        this.isAllowedToSee = true;
        const bars = response.data["bars"];
        const users = response.data["users"];
        const newUsers = response.data["newUsers"];
        const specialEvents = response.data["specialEvents"];
        this.bars = bars;
        this.users = users;
        this.specialEvents = specialEvents;
      } else {
        this.$router.push(`/`);
      }
      this.refreshSessionPeriodically();
    },
    async refreshSessionPeriodically() {
      setInterval(async () => {
        let response = await BarService.isAuthed({});
        if (response && response.data && !response.data.isSuccess) {
          this.$router.push(`/`);
        }
      }, 1000000);
    },
    async sendNotification() {
      const response = await AuthenticationService.pushNotification({
        expoTitle: this.expoTitle,
        expoBody: this.expoBody,
        expoJson: this.expoJson,
      });
      console.log("push notification: ", response);
    },
    onBarChange(event) {
      if (
        $(event.target).children(":selected").val() &&
        $(event.target).children(":selected").val() !== "Select bar"
      ) {
        this.selectedBarId = event.target.value;
        this.selectedBarName = $(event.target).children(":selected").text();
      } else {
        this.selectedBarId = null;
        this.selectedBarName = null;
      }
    },
    onUserChange(event) {
      if (event.target.value) {
        this.selectedUserId = event.target.value;
      } else {
        this.selectedUserId = null;
      }
    },
    async createBar() {
      const response = await AuthenticationService.createBar({
        name: this.barname,
      });
    },
    async deleteBar() {
      const response = await AuthenticationService.deleteBar({
        id: this.selectedBarId,
      });
      if (response.isSuccess) {
        window.location.reload();
      }
    },
    async deactivateBar() {
      const response = await AuthenticationService.deactivateBar({
        id: this.selectedBarId,
      });
    },
    async getBarDetails() {
      const response = await AuthenticationService.getBarDetails({
        id: this.selectedBarId,
      });
    },
    async getUserDetails() {
      const response = await AuthenticationService.getUserDetails({
        id: this.selectedUserId,
      });
      if (response && response.data) {
        this.userInformation = response.data;
      }
      console.log("user details here: ", response);
    },
    async makeUserAdmin() {
      let barId = this.selectedBarId;
      let userId = this.selectedUserId;
      console.log("bar id: ", barId, userId);
      const response = await AuthenticationService.makeUserAdmin({
        barId: barId,
        userId: userId,
      });
    },
    async removeUserAdmin() {
      let barId = this.selectedBarId;
      let userId = this.selectedUserId;
      const response = await AuthenticationService.removeUserAdmin({
        barId: barId,
        userId: userId,
      });
    },
    goToBar() {
      if (this.selectedBarId) {
        let url = window.location.origin;
        url += "/#/dashboard/" + this.selectedBarId;
        window.location.replace(url);
      }
    },
  },
};
</script>

<style scoped></style>
