<template>
  <div class="card-space">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <div class="row opening-hour-container">
          <div class="opening-hour-column">
            <label class="day-label">Next Monday</label>

            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.monday.openFrom"
                :valueTo="timesCopy.monday.openTo"
                :isClosed="timesCopy.monday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Monday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.monday.happyHourFrom"
                :valueTo="timesCopy.monday.happyHourTo"
                :isClosed="timesCopy.monday.isNoHappyHour"
                :smallerThan="
                  timesCopy.monday.isClosed ? '30:00' : timesCopy.monday.openTo
                "
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Monday"
              />

              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                day="monday"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                v-on:change-drink="changeDrink"
                :valueTo="timesCopy.monday.happyHourTo"
                :isClosed="timesCopy.monday.isNoHappyHour"
                :beer="timesCopy.monday.beer"
                :wine="timesCopy.monday.wine"
                :shot="timesCopy.monday.shot"
                :liquor="timesCopy.monday.liquor"
                :whiskey="timesCopy.monday.whiskey"
                :happyText="timesCopy.monday.happyText"
                :cocktail="timesCopy.monday.cocktail"
              />
            </div>
          </div>

          <div class="opening-hour-column">
            <label class="day-label">Next Tuesday</label>
            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.tuesday.openFrom"
                :valueTo="timesCopy.tuesday.openTo"
                :isClosed="timesCopy.tuesday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Tuesday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.tuesday.happyHourFrom"
                :valueTo="timesCopy.tuesday.happyHourTo"
                :isClosed="timesCopy.tuesday.isNoHappyHour"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Tuesday"
              />
              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                day="tuesday"
                v-on:change-drink="changeDrink"
                :isClosed="timesCopy.tuesday.isNoHappyHour"
                :beer="timesCopy.tuesday.beer"
                :wine="timesCopy.tuesday.wine"
                :shot="timesCopy.tuesday.shot"
                :liquor="timesCopy.tuesday.liquor"
                :whiskey="timesCopy.tuesday.whiskey"
                :happyText="timesCopy.tuesday.happyText"
                :cocktail="timesCopy.tuesday.cocktail"
              />
            </div>
          </div>

          <div class="opening-hour-column">
            <label class="day-label">Next Wednesday</label>
            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.wednesday.openFrom"
                :valueTo="timesCopy.wednesday.openTo"
                :isClosed="timesCopy.wednesday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Wednesday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.wednesday.happyHourFrom"
                :valueTo="timesCopy.wednesday.happyHourTo"
                :isClosed="timesCopy.wednesday.isNoHappyHour"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Wednesday"
              />
              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                day="wednesday"
                v-on:change-drink="changeDrink"
                :beer="timesCopy.wednesday.beer"
                :isClosed="timesCopy.wednesday.isNoHappyHour"
                :wine="timesCopy.wednesday.wine"
                :shot="timesCopy.wednesday.shot"
                :liquor="timesCopy.wednesday.liquor"
                :whiskey="timesCopy.wednesday.whiskey"
                :happyText="timesCopy.wednesday.happyText"
                :cocktail="timesCopy.wednesday.cocktail"
              />
            </div>
          </div>

          <div class="opening-hour-column">
            <label class="day-label">Next Thursday</label>
            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.thursday.openFrom"
                :valueTo="timesCopy.thursday.openTo"
                :isClosed="timesCopy.thursday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Thursday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.thursday.happyHourFrom"
                :valueTo="timesCopy.thursday.happyHourTo"
                :isClosed="timesCopy.thursday.isNoHappyHour"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Thursday"
              />
              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                day="thursday"
                v-on:change-drink="changeDrink"
                :beer="timesCopy.thursday.beer"
                :wine="timesCopy.thursday.wine"
                :shot="timesCopy.thursday.shot"
                :isClosed="timesCopy.thursday.isNoHappyHour"
                :liquor="timesCopy.thursday.liquor"
                :whiskey="timesCopy.thursday.whiskey"
                :happyText="timesCopy.thursday.happyText"
                :cocktail="timesCopy.thursday.cocktail"
              />
            </div>
          </div>

          <div class="opening-hour-column">
            <label class="day-label">Next Friday</label>
            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.friday.openFrom"
                :valueTo="timesCopy.friday.openTo"
                :isClosed="timesCopy.friday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Friday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.friday.happyHourFrom"
                :valueTo="timesCopy.friday.happyHourTo"
                :isClosed="timesCopy.friday.isNoHappyHour"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Friday"
              />
              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                day="friday"
                v-on:change-drink="changeDrink"
                :isClosed="timesCopy.friday.isNoHappyHour"
                :beer="timesCopy.friday.beer"
                :wine="timesCopy.friday.wine"
                :shot="timesCopy.friday.shot"
                :liquor="timesCopy.friday.liquor"
                :whiskey="timesCopy.friday.whiskey"
                :happyText="timesCopy.friday.happyText"
                :cocktail="timesCopy.friday.cocktail"
              />
            </div>
          </div>

          <div class="opening-hour-column">
            <label class="day-label">Next Saturday</label>
            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.saturday.openFrom"
                :valueTo="timesCopy.saturday.openTo"
                :isClosed="timesCopy.saturday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Saturday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.saturday.happyHourFrom"
                :valueTo="timesCopy.saturday.happyHourTo"
                :isClosed="timesCopy.saturday.isNoHappyHour"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Saturday"
              />
              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                day="saturday"
                v-on:change-drink="changeDrink"
                :isClosed="timesCopy.saturday.isNoHappyHour"
                :beer="timesCopy.saturday.beer"
                :wine="timesCopy.saturday.wine"
                :shot="timesCopy.saturday.shot"
                :liquor="timesCopy.saturday.liquor"
                :whiskey="timesCopy.saturday.whiskey"
                :happyText="timesCopy.saturday.happyText"
                :cocktail="timesCopy.saturday.cocktail"
              />
            </div>
          </div>

          <div class="opening-hour-column">
            <label class="day-label">Next Sunday</label>
            <div class="day-container">
              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.sunday.openFrom"
                :valueTo="timesCopy.sunday.openTo"
                :isClosed="timesCopy.sunday.isClosed"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="openFrom"
                keyTo="openTo"
                keyClose="isClosed"
                type="opening"
                v-on:change-is-closed="changeIsClosed"
                :onDaySelectChange="onDaySelectChange"
                day="Sunday"
              />

              <DayInputs
                :closedText="closedText"
                :valueFrom="timesCopy.sunday.happyHourFrom"
                :valueTo="timesCopy.sunday.happyHourTo"
                :isClosed="timesCopy.sunday.isNoHappyHour"
                v-on:change-from="changeFrom"
                v-on:change-to="changeTo"
                keyFrom="happyHourFrom"
                keyTo="happyHourTo"
                keyClose="isNoHappyHour"
                type="happy"
                v-on:change-is-closed="changeIsClosed"
                day="Sunday"
                :onDaySelectChange="onDaySelectChange"
              />

              <HappyHourPrices
                :isHappyHourValid="isHappyHourValid"
                v-on:on-happy-hour-day-change="onHappyHourDayChange"
                day="sunday"
                v-on:change-drink="changeDrink"
                :isClosed="timesCopy.sunday.isNoHappyHour"
                :beer="timesCopy.sunday.beer"
                :wine="timesCopy.sunday.wine"
                :shot="timesCopy.sunday.shot"
                :liquor="timesCopy.sunday.liquor"
                :whiskey="timesCopy.sunday.whiskey"
                :happyText="timesCopy.sunday.happyText"
                :cocktail="timesCopy.sunday.cocktail"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        :disabled="!isValid"
        @click="onButtonPress()"
        class="btn btn-primary save-btn"
      >
        {{ buttonText }}
      </button>
      <p style="margin-left: 20px" class="text text-danger" v-if="!isValid">
        {{ isValidMessage }}
      </p>
    </div>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import DayInputs from "./DayInputs";
import HappyHourPrices from "./HappyHourPrices";
export default {
  created: function () {
    this.validateOpeningTimes();
  },
  props: ["times", "buttonText", "title", "closedText", "type"],
  components: {
    VueTimepicker,
    DayInputs,
    HappyHourPrices,
  },
  data() {
    return {
      timesCopy: this.times,
      isValid: true,
      isValidMessage: "",
      selected: "",
      isHappyHourValid: true,
      allDays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
    };
  },
  computed: {},
  methods: {
    onDaySelectChange(event, keyFrom, keyTo, selectedDay) {
      if (event) {
        let dayToChange = event.target.id;
        let dayToCopyFrom = $(event.target).children("option:selected").val();
        if (dayToCopyFrom) {
          if (dayToCopyFrom != "all") {
            this.timesCopy[dayToChange][keyFrom] = this.timesCopy[
              dayToCopyFrom
            ][keyFrom];
            this.timesCopy[dayToChange][keyTo] = this.timesCopy[dayToCopyFrom][
              keyTo
            ];
          } else {
            this.allDays.forEach((dayEach, index) => {
              selectedDay = selectedDay.toLowerCase();
              this.timesCopy[dayEach][keyFrom] = this.timesCopy[selectedDay][
                keyFrom
              ];
              this.timesCopy[dayEach][keyTo] = this.timesCopy[selectedDay][
                keyTo
              ];
            });
          }
        }
      }
      this.validateOpeningTimes();
    },
    onHappyHourDayChange(event, keyTo) {
      let keyFrom = event.target.value;
      if (event) {
        if (keyFrom && keyTo) {
          if (keyFrom != "all") {
            this.timesCopy[keyTo]["beer"] = this.timesCopy[keyFrom]["beer"];
            this.timesCopy[keyTo]["wine"] = this.timesCopy[keyFrom]["wine"];
            this.timesCopy[keyTo]["whiskey"] = this.timesCopy[keyFrom][
              "whiskey"
            ];
            this.timesCopy[keyTo]["liquor"] = this.timesCopy[keyFrom]["liquor"];
            this.timesCopy[keyTo]["shot"] = this.timesCopy[keyFrom]["shot"];

            this.timesCopy[keyTo]["cocktail"] = this.timesCopy[keyFrom][
              "cocktail"
            ];
            this.timesCopy[keyTo]["happyText"] = this.timesCopy[keyFrom][
              "happyText"
            ];
          } else {
            this.allDays.forEach((day, index) => {
              this.timesCopy[day]["beer"] = this.timesCopy[keyTo]["beer"];
              this.timesCopy[day]["wine"] = this.timesCopy[keyTo]["wine"];
              this.timesCopy[day]["whiskey"] = this.timesCopy[keyTo]["whiskey"];
              this.timesCopy[day]["liquor"] = this.timesCopy[keyTo]["liquor"];
              this.timesCopy[day]["shot"] = this.timesCopy[keyTo]["shot"];
              this.timesCopy[day]["cocktail"] = this.timesCopy[keyTo][
                "cocktail"
              ];
              this.timesCopy[day]["happyText"] = this.timesCopy[keyTo][
                "happyText"
              ];
            });
          }
        }
      }
      this.validateOpeningTimes();
    },
    changeFrom(data, day, key) {
      if (data) {
        let newData = data.displayTime;
        this.timesCopy[day][key] = data.displayTime;
      }
      this.validateOpeningTimes();
    },
    changeTo(data, day, key) {
      if (data && day) {
        let newData = data.displayTime;
        this.timesCopy[day][key] = data.displayTime;
      }
      this.validateOpeningTimes();
    },
    validateOpeningTimes() {
      let isValid = true;
      let isValidMessage = "";
      this.allDays.forEach((day, index) => {
        let data = this.timesCopy[day];

        //Is from and to the same
        if (data.openFrom == data.openTo && !data.isClosed) {
          isValid = false;
        }
        //Is happy hour from and to the same
        if (data.happyHourFrom == data.happyHourTo && !data.isNoHappyHour) {
          isValid = false;
        }

        //Is opening time on correct fromat
        if (
          !this.isMilitaryTime(data.openFrom) ||
          !this.isMilitaryTime(data.openTo)
        ) {
          isValid = false;
        }

        //Is happy hour time on correct format
        if (
          !this.isMilitaryTime(data.happyHourFrom) ||
          !this.isMilitaryTime(data.happyHourTo)
        ) {
          isValid = false;
        }

        //Is happy hour valid
        if (!this.isHappyHourValid) {
          isValid = false;
        }
      });
      if (!isValid)
        this.isValidMessage =
          "Please make sure all the boxes above are green before saving. ";
      else this.isValidMessage = "";
      this.isValid = isValid;
    },
    changeIsClosed(data, day, key) {
      if (
        data &&
        day &&
        this.timesCopy &&
        this.timesCopy[day][key] !== undefined
      ) {
        this.timesCopy[day][key] = !this.timesCopy[day][key];
      }
      this.validateOpeningTimes();
    },
    onButtonPress() {
      this.$emit("on-handle-opening", this.timesCopy);
      this.validateOpeningTimes();
    },
    changeDrink(event, day, key, text) {
      if (event && day) {
        let price = event.target.value;
        if (price && !text) {
          if (price > 0 && this.timesCopy[day]["happyText"]) {
            price = parseInt(price.replace(/[^\d]/g, ""));
            if (
              confirm(
                "You will delete your happy hour text if you enter a number here, do you want to continue?"
              )
            ) {
              this.timesCopy[day]["happyText"] = "";
            }
          }
        }
        this.timesCopy[day][key] = price;
      }
    },
    isMilitaryTime(string) {
      return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
        string
      );
    },
  },
};
</script>

<style scoped>
.date-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opening-hour-column {
  margin: 15px;
  /* background-color: #f3f3f3; */
  padding: 5px;
  border-radius: 10px;
  width: 100%;
}

.day-container {
  display: flex;
  flex-direction: row;
  min-width: 150px;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  border-radius: 20px;
}

.opening-hour-container {
  display: flex;
  justify-content: space-around;
}
.day-label {
  font-size: 17px;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 15px;
}
</style>
