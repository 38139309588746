<template>
	<form @keyup.enter="login" @submit.prevent="login">
		<h1>Login</h1>

		<input
			type="email"
			placeholder="email"
			v-model="email"
			name="email"
			id="email"
		/>
		<input
			type="password"
			placeholder="password"
			v-model="password"
			name="password"
			id="password"
			
		/>
		<p class="error-text">{{ errorText }}</p>
		<p class="success-text">{{ successText }}</p>
		<router-link to="forgot"> Forgot password </router-link>
		<a href="/Login">Login</a>
		<button @click="login">Login</button>
		<!-- <input /> -->
	</form>
</template>

<script>
	import AuthenticationService from '@/services/AuthenticationService';
	export default {
		data() {
			return {
				email: '',
				password: '',
				successText: '',
				errorText: '',
			};
		},
		methods: {
			async login() {
				const response = await AuthenticationService.login({
					email: this.email,
					password: this.password,
				});
				if (response && response.data) {
					let data = response.data;
					if (data.success) {
						window.location.href = '' + window.location.origin + '';
						// this.successText = data.message;
					} else {
						this.errorText = data.message;
					}
				} else {
					this.errorText =
						'The service is under maintenance. Please try again later. ';
				}
			},
		},
	};
</script>

<style scoped>
	.error-text {
		color: red;
	}

	.success-text {
		color: green;
	}
</style>
