<template>
  <div>
    <!-- <a
      href="javascript:void(0)"
      v-if="itemIndex !== -1"
      class="cancel-button"
      v-on:click="$emit('on-close', false)"
      >&times;</a
    >-->

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="table-type">
          Select drink type
          <span class="required-ast">(*)</span>
        </label>
        <select class="form-control" required v-model="nType" id="table-type">
          <option value="Beer">Beer</option>
          <option value="Wine">Wine</option>
          <option value="Red wine">Red wine</option>
          <option value="White wine">White wine</option>
          <option value="Rose wine">Rose wine</option>
          <option value="Cocktail">Cocktail</option>
          <option value="Whiskey">Whiskey</option>
          <option value="Champagne">Champagne</option>
          <option value="Shot">Shot</option>
          <option value="Liquor">Liquor</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="table-name">Drink name (Optional)</label>
        <input
          placeholder="Corona for example"
          maxlength="30"
          id="table-name"
          class="form-control"
          v-model="nName"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-3">
        <label for="table-price">
          Regular drink price
          <span class="required-ast"></span>
        </label>
        <div class="flex">
          <input
            v-model="nPrice"
            type="number"
            maxlength="4"
            placeholder="1000 (Optional)"
            required
            class="form-control"
            id="table-price"
          />
          <span class="input-addition">kr</span>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label for="table-happy-price">
          Happy hour drink price
          <span class="required-ast"></span>
        </label>
        <div class="flex">
          <input
            v-model="nHappyPrice"
            type="number"
            maxlength="4"
            placeholder="1000 (Optional)"
            class="form-control"
            id="table-happy-price"
          />
          <span class="input-addition">kr</span>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="table-description">Short description (Optional)</label>
        <input
          maxlength="50"
          class="form-control"
          v-model="nDescription"
          placeholder="Discount on ... for example "
          id="table-description"
        />
      </div>
    </div>
    <!--
    <label>Select the days this happy hour drink is available</label>
    <label style="width: 200px;" class="checkbox-line">
      <input
        @click="onAllCheckboxClick"
        v-model="allCheckbox"
        type="checkbox"
      />
      Select all below
    </label>
  <div style="display: flex; flex-wrap: wrap;">
      <label class="checkbox-line">
        <input v-model="nDays" id="mon" value="Monday" type="checkbox" /> Monday
      </label>
      <label class="checkbox-line">
        <input v-model="nDays" id="tue" value="Tuesday" type="checkbox" />
        Tuesday
      </label>
      <label class="checkbox-line">
        <input v-model="nDays" id="wed" value="Wednesday" type="checkbox" />
        Wednesday
      </label>
      <label class="checkbox-line">
        <input v-model="nDays" id="thur" value="Thursday" type="checkbox" />
        Thursday
      </label>
      <label class="checkbox-line">
        <input v-model="nDays" id="fri" value="Friday" type="checkbox" /> Friday
      </label>
      <label class="checkbox-line">
        <input v-model="nDays" id="sat" value="Saturday" type="checkbox" />
        Saturday
      </label>
      <label class="checkbox-line">
        <input v-model="nDays" id="sun" value="Sunday" type="checkbox" /> Sunday
      </label>
    </div> -->
    <button @click="this.addDrink" class="btn btn-primary">
      {{ btnText }}
    </button>
    <button v-on:click="$emit('on-close', false)" class="btn btn-primary">
      {{ cancelBtnText }}
    </button>
  </div>
  <!-- <vue-timepicker format="HH:mm:ss"></vue-timepicker> -->
</template>

<script>
// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
// CSS
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  props: [
    "title",
    "type",
    "name",
    "alcohol",
    "ml",
    "price",
    "happyPrice",
    "days",
    "description",
    "btnText",
    "itemIndex",
    "cancelBtnText",
    "compId",
    "drinkId",
  ],
  components: { VueTimepicker },
  data() {
    return {
      nType: this.type,
      nName: this.name,
      nDays: this.days ? this.days : [],
      // nMl: this.ml,
      // nAlcohol: this.alcohol,
      nPrice: this.price,
      nHappyPrice: this.happyPrice,
      nDescription: this.description,
      nDrinkId: this.drinkId,
      allCheckbox: false,
    };
  },
  methods: {
    addDrink(index) {
      let obj = {
        name: this.nName,
        type: this.nType,
        // alc: this.nAlcohol,
        // ml: this.nMl,
        price: this.nPrice,
        happyPrice: this.nHappyPrice,
        days: this.nDays,
        description: this.nDescription,
        drinkId: this.nDrinkId,
      };
      this.$emit("on-handle-drink", this.itemIndex, obj);
    },
    onAllCheckboxClick() {
      if (!this.allCheckbox) {
        this.nDays = [];
        this.nDays.push(
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday"
        );
      } else {
        this.nDays = [];
      }
    },
  },
};
</script>

<style scoped>
.error-text {
  color: red;
}

.success-text {
  color: green;
}
.table-input {
  margin: 0px;
  padding: 0px;
}
.table-td-input {
  padding: 0px !important;
}
.small-table-input {
  width: 100%;
}
.small-fixed-table-input {
  width: 80px;
}
.checkbox-line {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.checkbox-line > input {
  margin-right: 5px;
  margin-bottom: 2px;
}
th {
  padding: 0px;
  padding-bottom: 3px;
}
.select-box {
  height: 30px;
}
.cancel-button {
  position: absolute;
  justify-content: flex-end;
  align-items: flex-end;
  right: 15px;
  top: 14px;
}
</style>
