// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import VueFlashMessage from 'vue-flash-message';

// import ToggleButton from "vue-js-toggle-button";
// Vue.use(ToggleButton);
// import VCalendar from "../node_modules/v-calendar/lib/components/calendar.umd";
// import VCalendar from "../node_modules/v-calendar/lib/v-calendar.umd";
// Vue.use(VCalendar);
// import Vue from "vue";
// import Calendar from "v-calendar/lib/components/calendar.umd";
// Vue.use(VCalendar);

import VCalendar from 'v-calendar';

Vue.use(VCalendar);
Vue.use(VueFlashMessage);
require('vue-flash-message/dist/vue-flash-message.min.css');

// import fullCalendar from "vue-fullcalendar";
// Vue.component("full-calendar", fullCalendar);
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";

Vue.config.productionTip = false;

/* eslint-disable no-new */

new Vue({
	render: (h) => h(App),
	router: router,
}).$mount('#app');

// new Vue({
//   el: "#app",
//   router,
//   components: { App },
//   template: "<App/>",
// });
