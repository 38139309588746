<template>
  <div>
    <div style="display: block;" v-if="images && images.length > 0">
      <div style="display: block; margin-bottom: 20px;">
        <h1>Images</h1>
        <button @click="confirmAll()" class="btn btn-primary">
          Confirm all
        </button>
      </div>
      <div
        style="display: inline-block; width: 320px;"
        v-for="image in images"
        :key="image.index"
        :value="image.name"
      >
        <h5>{{ image.bar + " - " + image.type }}</h5>
        <p>{{ image.name }}</p>
        <img style="width: 300px; height: 300px;" :src="image.url" />
        <a
          target="_blank"
          class="btn btn-primary"
          :href="'/#/dashboard/' + image.barId"
        >
          Go to bar
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";

export default {
  name: "confirmimages",
  mounted() {
    this.getData();
  },
  data() {
    return {
      images: null,
    };
  },
  methods: {
    async getData() {
      const response = await AuthenticationService.loadImages();
      if (response && response.data && response.data.isSuccess) {
        this.images = response.data.images;
      } else {
        this.$router.push(`/`);
      }
    },
    async confirmAll() {
      if (confirm("Do you want to confirm all images? ")) {
        const response = await AuthenticationService.confirmAllImages();
        window.location.reload();
      }
    },
    goToBar(id) {
      // this.$router.push("/#/dashboard/"+id);
    },
  },
};
</script>

<style scoped>
</style>