var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:this.isMilitaryTime(_vm.valueFrom) &&
    this.isMilitaryTime(_vm.valueTo) &&
    !(_vm.valueFrom === _vm.valueTo && !_vm.isClosed)
      ? 'column-wrap success-column'
      : 'column-wrap error-column'},[_c('span',{staticClass:"card-icon",class:_vm.type === 'happy' ? 'fas fa-beer' : 'fas fa-clock'}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"column-label"},[_vm._v(_vm._s(_vm.type === "happy" ? "Happy hour times" : "Opening times")+" "+_vm._s("(" + _vm.day + ")"))])]),(
        !(
          this.isMilitaryTime(_vm.valueFrom) ||
          (!this.isMilitaryTime(_vm.valueTo) && !_vm.isClosed)
        )
      )?_c('div',[_c('span',{staticClass:"text-danger"},[_vm._v("Error")])]):_c('div',[_c('span')])]),_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('input',{attrs:{"id":'closed-' + _vm.day + _vm.type,"type":"checkbox","disabled":!(
            this.isMilitaryTime(_vm.valueFrom) ||
            (!this.isMilitaryTime(_vm.valueTo) && !_vm.isClosed)
          )},domProps:{"checked":_vm.isClosed},on:{"change":function($event){_vm.$emit('change-is-closed', $event, _vm.day.toLowerCase(), _vm.keyClose)}}}),_c('label',{staticStyle:{"margin-left":"10px","margin-bottom":"0px !important"},attrs:{"for":'closed-' + _vm.day + _vm.type}},[_vm._v(" "+_vm._s(_vm.type === "happy" ? "No happy hour on " : "Bar is closed on")+" "+_vm._s(_vm.day)+" ")])])]),(
      !(
        this.isMilitaryTime(_vm.valueFrom) ||
        (!this.isMilitaryTime(_vm.valueTo) && !_vm.isClosed)
      )
    )?_c('div',[_c('span',{staticClass:"text-danger",staticStyle:{"display":"block"}},[_vm._v("Make sure no time is invalid below before continuing")])]):_vm._e(),(!_vm.isClosed)?_c('div',[_c('div',[_vm._m(0),_c('vue-timepicker',{staticClass:"timebox",attrs:{"required":"","value":_vm.valueFrom,"maxlength":"5","minute-interval":5,"manual-input":true,"format":"HH:mm"},on:{"change":function($event){_vm.$emit('change-from', $event, _vm.day.toLowerCase(), _vm.keyFrom)}}})],1),_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(this.isMilitaryTime(_vm.valueFrom) ? "" : "This time is invalid.")+" ")]),_c('div',[_vm._m(1),_c('vue-timepicker',{staticClass:"timebox",attrs:{"value":_vm.valueTo,"required":"","minute-interval":5,"maxlength":"5","manual-input":true,"format":"HH:mm"},on:{"change":function($event){_vm.$emit('change-to', $event, _vm.day.toLowerCase(), _vm.keyTo)}}})],1),_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(this.isMilitaryTime(_vm.valueTo) ? "" : "This time is invalid.")+" ")]),(_vm.valueFrom === _vm.valueTo)?_c('span',{staticClass:"text-danger"},[_vm._v("From and to time cannot be the same.")]):_vm._e(),_c('hr',{staticStyle:{"margin-bottom":"0px !important"}}),_c('SetToSameDay',{attrs:{"day":_vm.day.toLowerCase()},on:{"day-select-change":function($event){return _vm.onDaySelectChange($event, _vm.keyFrom, _vm.keyTo, _vm.day)}}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label"},[_c('label',[_vm._v("From:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label"},[_c('label',[_vm._v("To:")])])}]

export { render, staticRenderFns }