<template>
  <div>
    <div>
      <h2>New special event</h2>
      <label>Name</label>
      <input v-model="name" placeholder="" />
      <label>Description</label>
      <textarea v-model="description" placeholder=""></textarea>
      <label>Image link</label>
      <input v-model="imageLink" placeholder="" />
      <label>Date</label>
      <input placeholder="YYYY-MM-DD" v-model="date" />
      <label>Icon</label>
      <input v-model="icon" placeholder="" />
      <label>Color</label>
      <input v-model="color" placeholder="" />
      <label>Filter</label>
      <input v-model="filter" placeholder="" />
      <label>Sort</label>
      <input v-model="sort" placeholder="" />
      <button @click="createEvent">Create specal event</button>
    </div>
    <button @click="showSpecialEventsFun()">See special events</button>
    <div v-if="showSpecialEvents" class="container">
      <div
        class="box"
        v-for="(specialEvent, index) in specialEvents"
        :key="specialEvent.id"
      >
        <SpecialEvent
          :name="specialEvent.name"
          :id="specialEvent._id"
          :date="specialEvent.date"
          :description="specialEvent.description"
          :imageLink="specialEvent.imageLink"
          :icon="specialEvent.icon"
          :color="specialEvent.color"
          :filter="specialEvent.filter"
          :sort="specialEvent.sort"
        />
      </div>

    </div>
    <p>{{specialEventMessage}}</p>
  </div>
</template>
<script>
import AuthenticationService from "@/services/AuthenticationService";
import SpecialEvent from "./SpecialEvent";

export default {
  props: ["specialEvents"],
  components: {
    SpecialEvent,
  },
  data() {
    return {
      name: "",
      description: "",
      imageLink: "",
      date: "",
      icon: "",
      color: "",
      filter: "",
      sort: "",
      specialEventMessage: "",
      showSpecialEvents: false,
    };
  },
  methods: {
    createEvent: async function () {
      let name = this.name;
      let description = this.description;
      let imageLink = this.imageLink;
      let date = this.date;
      let icon = this.icon;
      let color = this.color;
      let filter = this.filter;
      let sort = this.sort;
      const response = await AuthenticationService.createSpecialEvent({
        name,
        description,
        imageLink,
        date,
        icon,
        color,
        filter,
        sort,
      });
      if (response && response.data && !response.data.isSuccess) {
        this.specialEventMessage = response.data.message;
      }
    },
    showSpecialEventsFun: function () {
      this.showSpecialEvents = true;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}

.box {
  width: 300px;
  border: 1px solid black;
  margin: 5px;
}
</style>