<template>
	<div class="main-container">
		<div>
			<flash-message class="flash"></flash-message>
			<div v-if="!isLoading" class="card-app">
				<div
					class="card-inner-container"
					v-if="this.authenticatedStatus === 'notloggedin'"
				>
					<h1 class="text-center header">Barhopp</h1>
					<p class="text-center" style="font-size: 12px">
						Company dashboard for a free advertisement service
					</p>

					<div class="sign-container">
						<a
							style="margin-right: 20px"
							v-bind:class="{ active: !isSignUp, 'sign-link': true }"
							href="javascript:void(0)"
							@click="onSignInClick"
							>Sign in</a
						>
						<a
							v-bind:class="{ active: isSignUp, 'sign-link': true }"
							@click="onSignUpClick"
							href="javascript:void(0)"
							>Sign up</a
						>
					</div>
					<div>
						<div class="input-container">
							<label class="input-label">Email</label>
							<input 
								v-on:keyup.enter="onEnter" 
							
							v-model="email" class="input" type="email" />
						</div>
						<div class="input-container">
							<label class="input-label">Password</label>
							<input
								class="input"
								v-model="password"
								type="password"
								v-on:keyup.enter="onEnter" 
							/>
						</div>
						<div
							v-if="isSignUp"
							style="
								display: flex;
								margin-bottom: 20px;
								margin-top: 20px;
								align-items: center;
							"
						>
							<input
								style="
									width: 20px;
									height: 20px;
									margin-top: -3px;
									margin-right: 15px;
									"
								id="manage"
								v-model="confirm"
								type="checkbox"
							/>
							<label style="color: white; width: 100%" for="manage">
								I confirm that I work at a bar, own a bar or have authorization
								to manage bar's information (proof of
								work/ownership/authorization might have to be established).
							</label>
						</div>
						<div>
							<p class="error-message">{{ errorMessage }}</p>
						</div>
						<div
							style="color: white"
							class="text-center"
							v-if="showEmailSuccess"
						>
							Your email has been confirmed
						</div>
						<div style="color: red" class="text-center" v-if="showEmailFail">
							Your email could not be confirmed. The link you used might have
							been used before.
						</div>
						<button
							v-if="!isSignUp"
							@click="onSignIn"
							class="button btn btn-primary"
						>
							Sign in
						</button>
						<button
							v-if="!isSignUp"
							@click="onChangePassword"
							class="button btn btn-info"
						>
							Forgot password
						</button>
						<button
							v-else
							@click="onSignUp"
							:disabled="!confirm"
							class="button btn btn-primary"
						>
							Sign up
						</button>
						<button
							@click="onResendEmailConfirmation"
							class="button btn btn-info"
						>
							Re-send email confirmation
						</button>
						<button @click="routeToHome" class="button btn btn-info">
							Go back
						</button>
					</div>
				</div>
				<div
					class="card-inner-container"
					v-if="this.authenticatedStatus === 'loggedin'"
				>
					<div v-if="!showTwoFa">


						<h1 class="text-center header">Barhopp</h1>
						<p class="text-center" v-if="name">
							Dear {{ name }}, you are logged in.
						</p>
						<div v-else>
							<p class="text-center">You are logged in</p>
							<p class="text-center">{{ email }}</p>
						</div>
						<div class="input-container">
							<label class="input-label">Bars you manage:</label>
							<select v-model="selectedBarId" class="input" type="email">
								<!-- v-if="!bars || bars < 1" -->
								<option value="">No bar selected</option>
								<option
									v-for="(item, index) in bars"
									:key="index"
									:value="item._id"
								>
									{{
										`${item.name} ${item.isActive ? ' (Active)' : ' (Inactive)'}`
									}}
								</option>
							</select>

							<p class="text-center" style="margin-top: 15px">
								Please contact support@barhopp.live to manage a bar. You might
								have to provide a proof that you are allowed to manage this bar.
							</p>

							<button
								:disabled="!bars || bars.length < 1"
								@click="onManageBarClick"
								class="button btn btn-primary"
							>
								Manage bar
							</button>
							<button @click="onChangePassword" class="button btn btn-info">
								Change password
							</button>
							<button @click="onRemoveTwoFa" class="button btn btn-info">
								Remove 2FA authentication
							</button>
							<button @click="routeToHome" class="button btn btn-info">
								Go back
							</button>
							<button @click="logout" class="button btn btn-danger">
								Log out
							</button>
							<p class="text-center" style="margin-top: 15px">
								For further account management, please contact
								support@barhopp.live.
							</p>
						</div>
					</div>
					<div v-else>
						<div>
							<div v-if="isTwoFaSetup">
								<h1 class="text-center header">Add your code</h1>
									<p class="text-center">
										Add the code from your two factor authentication app in your phone.
									</p>
								<div class="input-container">
									<!-- <label class="input-label">Password</label> -->
									<input
										type="text"
										placeholder="Code"
										v-model="faToken"
										class="input"
										name="faToken"
										id="faToken"
									/>
								</div>
							</div>
							<div v-else>
								<h1 class="text-center header">Last step</h1>
								<p class="text-center">
									Your security is our priority. Help us keep your account secure.
								</p>
								<p class="">
									1. Scan the QR code below with your Google Authenticator app on your phone. You can get the app on your <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a> or <a target="_blank" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" >Android phone.</a> 
								</p>
								<img style="margin: auto; display: flex;" :src="this.twoFaQR" />
								<p style="margin-top: 25px;">
									2. Enter the number displayed in the app.
								</p>
								<div class="input-container">
									<!-- <label class="input-label">Password</label> -->
									<input
										type="text"
										placeholder="Code"
										v-model="faToken"
										class="input"
										name="faToken"
										id="faToken"
									/>
								</div>
								<a v-if="!this.showTwoFaHelp" style="margin-top: 15px;" href="javascript:void(0)" @click="() => this.showTwoFaHelp = true" >Your code doesn't work?</a>
								<p v-else>If you have tried to enter several codes and they do not work then you should add a new 2FA authentication entry in your app. Click the <u>Remove 2FA Authentication button below</u>, go to your email, press the link in your email. 
								Then you will have the opporutinity to scan another QR code in your app. If you already have an old Barhopp entry in your 2FA authentication app then you
								should remove that. Feel free to contact us at support@barhopp.live if you run into problems.</p>
							</div>
						</div>
						
						<button
							@click="onSubmitCode"
							class="button btn btn-primary"
						>
							Submit code
						</button>
						<button @click="onRemoveTwoFa" class="button btn btn-info">
							Remove 2FA authentication
						</button>
						<button @click="routeToHome" class="button btn btn-info">
							Go back
						</button>
						<button @click="logout" class="button btn btn-danger">
							Log out
						</button>
					</div>
				</div>
			</div>
			<div v-if="isLoading">
				<div
					style="margin: auto; margin-top: 50px; text-align: center"
					class="loader"
				></div>
			</div>
		</div>
	</div>
	<!-- <div class="hello">
    <label>Staðir sem þú hefur umsjón yfir:</label>
    <select v-model="selected">
      <option>Veldu bar</option>
      <option value="33">Kíkí</option>
      <option value="44">American</option>
    </select>
    <button @click="changeCompany">Velja</button>


  </div>-->
</template>

<script>
	import AuthenticationService from '@/services/AuthenticationService';
	import { toDataURL } from 'qrcode';
	import QrcodeVue from 'qrcode.vue'

	export default {
		name: 'Main',
		components: {
			QrcodeVue,
		},
		mounted() {
			this.onload();
			this.showEmailSuccess = window.location.href.includes('emailsuccess')
				? true
				: false;
			this.showEmailFail = window.location.href.includes('emailfail')
				? true
				: false;
		},
		data() {
			return {
				selected: '',
				signInSelected: true,
				errorMessage: '',
				authenticatedStatus: 'notloggedin',
				isSignUp: false,
				isLoading: false,
				name: '',
				confirm: false,
				selectedBarId: '',
				email: '',
				password: '',
				bars: null,
				showEmailFail: false,
				showEmailSuccess: false,
				showAdvanced: false,
				isTwoFaSetup: false,
				twoFaQR: '',
				faToken: '',
				showTwoFa: true,
				showTwoFaHelp: false,
			};
		},
		methods: {
			async onload() {
				this.isLoading = true;
				const response = await AuthenticationService.loadMain();
				if (response && response.data) {
					if (response.data.isAuth) {
						this.authenticatedStatus = 'loggedin';
						this.bars = response.data.bars;
						this.isTwoFaSetup = response.data.isTwoFaSetup;
						this.showTwoFa = response.data.showTwoFa;
						if(response.data.twoFaSecret) {
							const dataUrl = await toDataURL(response.data.twoFaSecret)
							this.twoFaQR = dataUrl;
						}
					} else {
						this.authenticatedStatus = 'notloggedin';
					}
				}
				this.isLoading = false;
			},
			onEnter() {
				if (this.isSignUp) {
					if (this.confirm) {
						this.onSignUp();
					}
				} else {
					this.onSignIn();
				}
			},
			onManageBarClick() {
				if (this.selectedBarId) {
					this.$router.push(`dashboard/${this.selectedBarId}`);
				} else {
					this.flash('No bar selected', 'error');
				}
			},
			trigger() {
				this.$refs.enterClick.click();
			},
			routeToHome() {
				this.$router.push(`/`);
			},
			onSignUpClick() {
				this.isSignUp = true;
			},
			shouldShowAdvanced() {
				this.showAdvanced = true;
			},
			onSignInClick() {
				this.isSignUp = false;
			},
			async onSubmitCode() {
				const response = await AuthenticationService.submitTwoFactorCode({
					token: this.faToken
				});

				this.handleResponse(response.data);
				if(response.data.verified) { 
					location.reload();
				}
			},
			async onSignUp() {
				this.errorMessage = '';
				this.isLoading = true;
				const response = await AuthenticationService.register({
					email: this.email,
					password: this.password,
				});
				this.handleResponse(response.data);
			},
			async onSignIn() {
				this.errorMessage = '';
				this.isLoading = true;
				const response = await AuthenticationService.login({
					email: this.email,
					password: this.password,
				});
				this.handleResponse(response.data, 2700, false, true);
			},
			async onChangePassword() {
				if (
					confirm(
						'Are you sure you want receive an email to change your password?'
					)
				) {
					this.isLoading = true;
					const response = await AuthenticationService.onChangePassword({
						email: this.email,
					});
					if (response) {
						this.handleResponse(response.data, 2000);
					} else {
						this.isLoading = false;
					}
				}
			},
			async onRemoveTwoFa() {
				if(confirm('Are you sure you want to receive an email to remove your 2FA authentication?')) {
					this.isLoading = true;
					const response = await AuthenticationService.onRemoveFa();
					this.handleResponse(response.data, 10000, false);
				}
			},
			async onResendEmailConfirmation() {
				this.isLoading = true;
				if (
					this.email &&
					this.email.length > 4 &&
					this.password &&
					this.password.length > 4
				) {
					const response = await AuthenticationService.onResendEmailConfirmation(
						{
							email: this.email,
							password: this.password,
						}
					);
					if (response) {
						this.handleResponse(response.data, 5000);
					} else {
						this.isLoading = false;
					}
				} else {
					this.handleResponse({
						isSuccess: false,
						message:
							'Please write your email address and password in the input boxes above',
					});
				}
			},
			async logout() {
				this.isLoading = true;
				const response = await AuthenticationService.logout();
				this.handleResponse(response.data, 500);
			},
			handleResponse(response, timeout = 2000, noReload = false, longLoad = false) {
				if(longLoad) {
					this.isLoading = true;
				} else {
					this.isLoading = false;
				}
				if (response.isSuccess) {
					this.flash(response.message, 'success', {
						timeout: timeout,
						beforeDestroy() {
							this.isLoading = false;
							if(!noReload) {
								window.location.reload();
							}
						},
					});
				} else {
					this.flash(response.message, 'error');
					this.isLoading = false;
					if(response.message.includes('not authenticated')) {
						this.authenticatedStatus = 'notloggedin';
					}
				}
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	h1,
	h2 {
		font-weight: normal;
		color: white;
		/* margin-bottom: 50px; */
		font-weight: 600;
	}
	ul {
		list-style-type: none;
		padding: 0;
	}
	li {
		display: inline-block;
		margin: 0 10px;
	}
	a {
		color: #42b983;
	}
	p {
		color: white;
	}

	.sign-link {
		text-transform: uppercase;
		color: white;
		font-weight: 600;
		font-size: 22px;
		text-decoration: none;
		transition: 2s;
		display: flex;
		width: 100px;
		flex-direction: column;
	}
	.sign-link.active {
		/* border-bottom: 2px solid blue; */
		transition: 2s;
		width: 100px;
		align-items: center;
		display: flex;
	}
	.sign-link.active::after {
		content: ' ';
		height: 2px;
		border-bottom-style: solid;
		border-bottom-width: 2px;
		display: table-cell;
		width: 85px;
		color: var(--main-color);
	}
	.sign-link:not(.active) {
		color: 'gray';
		opacity: 0.6;
	}
</style>
