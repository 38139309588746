<template>
  <div>

      <div>
        <a
          v-if="!showInstructions"
          @click="onShowInstructions"
          href="javascript:void(0)"
        >
          How to get these numbers?
        </a>
        <div v-else>
          <p style="font-weight: 500">Instructions:</p>
          <ol style="margin-left: -20px;">
            <li>Open maps.google.com</li>
            <li>
              Right click on the location of your place in Google Maps
            </li>
            <li>
              Click on
              <span style="font-style: italic">What's here?</span>
            </li>
            <li>
              Copy these two numbers, they might look something like this:
              <span class="font-style: italic"
                >64.145550 (lat), -21.931040 (long)</span
              >
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showInstructions: false,
    };
  },
  methods: {
    onShowInstructions() {
      this.showInstructions = true;
    }
  }
};
</script>

<style scoped></style>
