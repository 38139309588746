<template>
  <div>
    <h5>Date: {{ normalizeDate() }}</h5>
    <h5>Name: {{ name }}</h5>
    <p>Id: {{ id }}</p>
    <div class="form-group">
      <label>Name:</label>
      <input class="form-control" v-model="newName" />
    </div>
    <div class="form-group">
      <label>Date:</label>
      <input class="form-control" v-model="newDate" />
    </div>
    <div class="form-group">
      <label>Description:</label>
      <textarea
        maxlength="50"
        class="form-control"
        v-model="newDescription"
      ></textarea>
    </div>
    <div class="form-group">
      <label>Image link:</label>
      <input class="form-control" v-model="newImageLink" />
    </div>
    <div class="form-group">
      <label>Icon:</label>
      <input class="form-control" v-model="newIcon" />
    </div>
    <div class="form-group">
      <label>Filter:</label>
      <input class="form-control" v-model="newFilter" />
    </div>
    <div class="form-group">
      <label>Sort:</label>
      <input class="form-control" v-model="newSort" />
    </div>
    <button class="btn btn-primary" @click="changeSpecialEvent()">
      Save changes
    </button>
    <button class="btn btn-danger" @click="deleteSpecialEvent()">Delete</button>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import moment from "moment";
export default {
  props: ["name", "date", "id", "description", "imageLink", "icon", "color"],
  data() {
    return {
      newName: this.name,
      newDate: this.date,
      newDescription: this.description,
      newImageLink: this.imageLink,
      newIcon: this.icon,
      newColor: this.color,
      newFilter: this.filter,
      newSort: this.sort,
    };
  },
  methods: {
    normalizeDate: function () {
      return moment(this.date, "YYYY-MM-DD").format("LL");
    },
    changeSpecialEvent: async function () {
      if (confirm("Do you want to edit this event?")) {
        let editSpecialEvent = await AuthenticationService.editSpecialEvent({
          name: this.newName,
          description: this.newDescription,
          imageLink: this.newImageLink,
          date: this.newDate,
          icon: this.newIcon,
          color: this.newColor,
          filter: this.newFilter,
          sort: this.newSort,
          id: this.id,
        });
      }
    },
    deleteSpecialEvent: async function () {
      if (confirm("Do you want to delete this event?")) {
        let editSpecialEvent = await AuthenticationService.deleteSpecialEvent({
          id: this.id,
        });
      }
    },
  },
};
</script>

<style scoped>
</style>