<template>
  <div
    :class="
      this.isMilitaryTime(valueFrom) &&
      this.isMilitaryTime(valueTo) &&
      !(valueFrom === valueTo && !isClosed)
        ? 'column-wrap success-column'
        : 'column-wrap error-column'
    "
  >
    <span
      class="card-icon"
      :class="type === 'happy' ? 'fas fa-beer' : 'fas fa-clock'"
    ></span>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div style="display: flex; align-items: center">
        <span class="column-label"
          >{{ type === "happy" ? "Happy hour times" : "Opening times" }}
          {{ "(" + day + ")" }}</span
        >
      </div>
      <div
        v-if="
          !(
            this.isMilitaryTime(valueFrom) ||
            (!this.isMilitaryTime(valueTo) && !isClosed)
          )
        "
      >
        <!-- <span class="fas fa-exclamation-triangle text-danger"></span> -->
        <span class="text-danger">Error</span>
      </div>
      <div v-else>
        <!-- <span class="fas fa-check-circle text-success"></span> -->
        <span></span>
      </div>
    </div>
    <div style="width: 100%">
      <div style="display: flex; align-items: center">
        <input
          v-on:change="
            $emit('change-is-closed', $event, day.toLowerCase(), keyClose)
          "
          :id="'closed-' + day + type"
          type="checkbox"
          :checked="isClosed"
          :disabled="
            !(
              this.isMilitaryTime(valueFrom) ||
              (!this.isMilitaryTime(valueTo) && !isClosed)
            )
          "
        />
        <label
          style="margin-left: 10px; margin-bottom: 0px !important"
          :for="'closed-' + day + type"
        >
          {{ type === "happy" ? "No happy hour on " : "Bar is closed on" }}
          {{ day }}
        </label>
      </div>
    </div>
    <div
      v-if="
        !(
          this.isMilitaryTime(valueFrom) ||
          (!this.isMilitaryTime(valueTo) && !isClosed)
        )
      "
    >
      <span class="text-danger" style="display: block"
        >Make sure no time is invalid below before continuing</span
      >
    </div>

    <div v-if="!isClosed">
      <div>
        <div class="label">
          <label>From:</label>
        </div>
        <!-- <input
          type="text"
          @change="validateTime(this, 'from')"
          placeholder="hh:mm"
        /> -->
        <vue-timepicker
          required
          :value="valueFrom"
          maxlength="5"
          :minute-interval="5"
          :manual-input="true"
          v-on:change="$emit('change-from', $event, day.toLowerCase(), keyFrom)"
          class="timebox"
          format="HH:mm"
        ></vue-timepicker>
      </div>
      <p class="error-text">
        {{ this.isMilitaryTime(valueFrom) ? "" : "This time is invalid." }}
      </p>
      <div>
        <div class="label">
          <label>To:</label>
        </div>
        <vue-timepicker
          :value="valueTo"
          v-on:change="$emit('change-to', $event, day.toLowerCase(), keyTo)"
          required
          :minute-interval="5"
          maxlength="5"
          :manual-input="true"
          class="timebox"
          format="HH:mm"
        ></vue-timepicker>
      </div>

      <p class="error-text">
        {{ this.isMilitaryTime(valueTo) ? "" : "This time is invalid." }}
      </p>

      <!-- <p class="error-text">
        {{
          this.isHappyHourTooLong(valueTo)
            ? "This happy hour finish time cannot go past the opening time of this bar."
            : ""
        }}
      </p> -->
      <span v-if="valueFrom === valueTo" class="text-danger"
        >From and to time cannot be the same.</span
      >
      <hr style="margin-bottom: 0px !important" />
      <SetToSameDay
        :day="day.toLowerCase()"
        v-on:day-select-change="onDaySelectChange($event, keyFrom, keyTo, day)"
      />
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import SetToSameDay from "./SetToSameDay";

export default {
  props: [
    "day",
    "valueFrom",
    "valueTo",
    "isClosed",
    "onDaySelectChange",
    "closedText",
    "keyFrom",
    "keyTo",
    "keyClose",
    "smallerThan",
    "type",
  ],
  components: {
    VueTimepicker,
    SetToSameDay,
  },
  // data() {
  //   return {
  //     isClosedTemp: this.isClosed
  //   };
  // },
  computed: {
    isBarClosed: function () {
      return $("#closed-" + this.day).is(":checked");
    },
  },
  methods: {
    isMilitaryTime(string) {
      return /(00|01|02|03|04|05|06|07|08|09|10|11|12|13|14|15|16|17|18|19|20|21|22|23):?(0|1|2|3|4|5)\d/.test(
        string
      );
    },
    validateTime(inputField, type) {
      var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
        inputField.value
      );

      if (isValid) {
        inputField.style.backgroundColor = "#bfa";
      } else {
        inputField.style.backgroundColor = "#fba";
      }

      return isValid;
    },
    isHappyHourTooLong(openToValue) {
      if (openToValue) {
        let openToValueArray = openToValue.split(":");
        if (openToValueArray.length > 1) {
          let openValueInt = parseInt(openToValueArray[0]);
          if (openValueInt < 5) {
            openValueInt + 24;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.label {
  margin-right: 8px;
  margin-top: 8px;
}

.success-column {
  /* background-color: #c8dccb; */
  border: green 1px solid;
  background-color: white;
}

.error-column {
  background-color: white;
  border: red 1px solid;
  /* background-color: #f1cece; */
}

/* .container {
  margin-top: 30px;
} */
</style>
