<template>
  <div>
    <CardText 
      title="Privacy Policy" 
      subTitle="for Barhopp.live website"
      type="privacyweb" 
       />
  </div>
</template>

<script>
import CardText from "./CardText";
export default {
  components: {
    CardText,
  },
  data() {
    return {

    };
  },
};
</script> 

<style scoped>
</style>