<template>
  <div>
    <div class="main-container">
      <div>
        <flash-message class="flash"></flash-message>
        <div class="card-app">
          <div class="card-inner-container">
            <h1 class="text-center header">Barhopp</h1>
            <p class="text-center" style="font-size: 16px">Reset password</p>
            <div class="input-container">
              <label for="password" class="input-label">Password</label>
              <input
                id="password"
                class="input"
                v-model="password"
                type="password"
              />
            </div>
            <div class="input-container">
              <label for="repeatpassword" class="input-label"
                >Repeat password</label
              >
              <input
                class="input"
                id="repeatpassword"
                v-model="repeatpassword"
                type="password"
                @keyup.enter="enterClicked()"
              />
            </div>
            <button @click="onResetPassword()" class="button btn btn-primary">
              Submit
            </button>
            <button @click="goBack()" class="button btn btn-primary">
              Go back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
export default {
  data() {
    return {
      email: "",
      password: "",
      repeatpassword: "",
    };
  },
  methods: {
    async onResetPassword() {
      let urlArray = window.location.href.split("/");
      let token = urlArray[urlArray.length - 1];
      if (!token || token.length < 20) {
        token = urlArray[urlArray.length - 2];
      }
      if (token && token.length > 20) {
        const response = await AuthenticationService.onResetPassword({
          password: this.password,
          repeatpassword: this.repeatpassword,
          token: token,
        });
        if (response && response.data) {
          this.handleResponse(response.data, 2000, true);
        }
      }
    },
    goBack() {
      this.$router.push(`/panel`);
    },
    enterClicked() {
      this.onResetPassword();
    },
    handleResponse(response, timeout = 2000, reload = false) {
      if (response.isSuccess) {
        let that = this;
        this.flash(response.message, "success", {
          timeout: timeout,
          beforeDestroy() {
            if (reload) {
              that.$router.push(`/panel`);
            }
          },
        });
      } else {
        this.flash(response.message, "error");
      }
    },
  },
};
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
  color: white;
  /* margin-bottom: 50px; */
  font-weight: 600;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
p {
  color: white;
}
.error-text {
  color: red;
}

.success-text {
  color: green;
}
</style>
