<template>
  <div>
    <label class="label" :for="day">Copy another day:</label>
    <div>
      <select
        class="special-select form-control"
        v-on:change="$emit('day-select-change', $event)"
        :id="day"
      >
        <option value>Select / Copy</option>
        <option value="monday">Set to same as Monday</option>
        <option value="tuesday">Set to same as Tuesday</option>
        <option value="wednesday">Set to same as Wednesday</option>
        <option value="thursday">Set to same as Thursday</option>
        <option value="friday">Set to same as Friday</option>
        <option value="saturday">Set to same as Saturday</option>
        <option value="sunday">Set to same as Sunday</option>
        <option style="font-weight: bold" value="all"
          >Set this time to all other days</option
        >
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: ["day"]
};
</script>

<style scoped>
.label {
  margin-top: 8px;
  color: gray;
}
</style>
