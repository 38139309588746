<template>
	<div>
		<!-- <simple-timeline
      :items="items"
      dateFormat="DD/MM/YYYY"
      v-on="$listeners"
      @timeline-edit="edit"
    ></simple-timeline>-->
		<!-- <full-calendar :events="fcEvents" locale="en"></full-calendar> -->
		<v-calendar
			:firstDayOfWeek="2"
			:min-date="new Date()"
			:attributes="dates"
			:dates="dates"
			is-expanded
			:rows="1"
			v-on:dayclick="onDayClick"
			:value="null"
		/>
		<div style="margin-top: 30px">
			<p style="font-weight: bold">Selected day: {{ selectedDate }}</p>

			<p v-if="!event" style="color: lightgray" class="text-center">
				No events have been added on this day
			</p>

			<Event
				:selectedDay="selectedDay"
				:selectedDate="selectedDate"
				:selectedDateKey="selectedDateKey"
				:event="event"
				v-on:add-event-press="onEditEvent"
				v-on:delete-event="onEventDelete"
				eventType="oneday"
			/>

			<div style="margin-top: 40px; display: flex; width: 200px">
				<button
					data-toggle="modal"
					v-on:click="onOpenAddEventModal('oneday')"
					data-target="#addEventModal"
					class="btn btn-primary"
					style="width: 500px"
					:disabled="event"
				>
					Create an event for {{ selectedDate }}
				</button>
			</div>
		</div>
		<div
			class="modal fade"
			id="addEventModal"
			tabindex="-1"
			data-backdrop="static"
			role="dialog"
			aria-labelledby="addEventModalTitle"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5
							v-if="eventType == 'oneday'"
							class="modal-title"
							id="exampleModalLongTitle"
						>
							Add event for {{ selectedDate }}
						</h5>
						<h5 v-else class="modal-title" id="exampleModalLongTitle">
							Add event for every {{ selectedDay }}
						</h5>
					</div>
					<div class="modal-body">
						<EditEvent
							v-on:on-close="onClose('add')"
							buttonText="Create event"
							secondButtonText
							v-on:on-manage-event-press="onManageEvent"
							:allEvents="events"
							action="add"
							:selectedDate="selectedDate"
							:selectedDateKey="selectedDateKey"
							:selectedDay="selectedDay"
						/>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade"
			id="editEventModal"
			tabindex="-1"
			data-backdrop="static"
			role="dialog"
			aria-labelledby="editEventModalTitle"
			aria-hidden="true"
		>
			<div class="modal-dialog modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5
							v-if="this.editEventType == 'oneday'"
							class="modal-title"
							id="exampleModalLongTitle"
						>
							Edit event for {{ selectedDate }}
						</h5>
						<h5 v-else class="modal-title" id="exampleModalLongTitle">
							Edit event for every {{ selectedDay }}
						</h5>
					</div>
					<div class="modal-body">
						<EditEvent
							v-on:on-close="onClose('edit')"
							v-on:on-manage-event-press="onManageEvent"
							buttonText="Save changes"
							:baseUrl="baseUrl"
							:eventType="editEventType"
							:allEvents="events"
							:deleteEvent="deleteEvent"
							:key="eventToEdit && ['_id'] ? eventToEdit['_id'] : '1234'"
							:event="this.eventToEdit"
							action="edit"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from 'moment';
	import AuthenticationService from '@/services/AuthenticationService';
	import Event from './Event';
	import EditEvent from './EditEvent';
	import Popper from '@popperjs/core';
	// import VCalendar from 'v-calendar';

	// import Calendar from "v-calendar/lib/components/calendar.umd";
	// Vue.component("calendar", Calendar);
	// import Vue from "vue";
	// import Vue from "vue";
	// import Calendar from "v-calendar/lib/components/calendar.umd";
	// Vue.use(Calendar);

	export default {
		props: ['events', 'days', 'baseUrl', 'deleteEvent'],
		components: {
			Event,
			EditEvent,
			// VCalendar,
			// Calendar,
		},
		created: function() {
			//Set red dots
			let dates = Object.keys(this.events).map((key, index) => {
				return {
					start: new Date(key),
					span: 1,
				};
			});

			let date = new Date();
			let year = date.getFullYear();
			let month = date.getMonth() + 1;
			let day = date.getDate();
			month = month.toString().length === 1 ? '0' + month : month;
			day = day.toString().length === 1 ? '0' + day : day;
			let newDateKey = `${year}-${month}-${day}`;
			this.selectedDateKey = newDateKey;
			this.selectedDay = moment().format('dddd') + ', ' + moment().format('ll');

			this.dates = [
				{
					key: newDateKey,
					highlight: true,
					popover: {
						label: "This is today's date",
					},
					dates: [new Date()],
				},
				{
					dot: 'blue',
					dates: dates,
				},
			];
			//}

			//Get date event
			let event = this.events[this.selectedDateKey];
			if (event) {
				this.event = event;
			}
		},
		data() {
			return {
				selectedDate: moment().format('dddd') + ', ' + moment().format('ll'),
				selectedDateKey: null,
				selectedDay: moment().format('dddd'),
				dates: null,
				eventType: null,
				event: null,
				everyWeekEvent: null,
				eventToEdit: null,
				editEventType: null,
			};
		},
		// watch: {},
		methods: {
			// getSelectedKey: function() {
			//   if (this.selectedDateKey === "today") {
			//     let date = new Date();
			//     let year = date.getFullYear();
			//     let month = date.getMonth() + 1;
			//     let day = date.getDate();
			//     month = month.toString().length === 1 ? "0" + month : month;
			//     day = day.toString().length === 1 ? "0" + day : day;
			//     let newDateKey = `${year}-${month}-${day}`;
			//     return newDateKey;
			//   } else {
			//     return this.selectedDateKey;
			//   }
			//   return this.selectedDateKey;
			// },
			onEditEvent(type) {
				if (type === 'oneday') {
					this.editEventType = 'oneday';
					this.eventToEdit = this.event;
				} else {
					this.editEventType = 'everyweek';
					this.eventToEdit = this.everyWeekEvent;
				}

				$('#editEventModal').modal('show');
			},
			onEventDelete(type, id, shouldDeactivate, day, dateKey) {
				this.$emit('on-delete-event', type, id, shouldDeactivate, day, dateKey);
			},
			onDayClick(date) {
				if (date) {
					this.selectedDateKey = date.id;
					this.selectedDate = date.ariaLabel;
					this.selectedDay = date.ariaLabel.split(',')[0];

					if (this.events) {
						this.event = this.events[this.selectedDateKey];
					}

					let everyWeekEvent = this.days[this.selectedDay.toLowerCase()].event;
					//Get every week event
					if (everyWeekEvent) {
						this.everyWeekEvent = everyWeekEvent;
					}
				}
			},
			onClose(closeType) {
				if (closeType === 'add') {
					$('#addEventModal').modal('hide');
				} else {
					$('#editEventModal').modal('hide');
				}
			},
			onOpenAddEventModal(type) {
				this.eventType = type;
			},
			onManageEvent(id, eventData) {
				this.$emit(
					'on-manage-event',
					id,
					eventData,
					this.selectedDateKey, //selected key here
					this.selectedDay, //day that is selected
					this.selectedDate //human readable date
					// name,
					// fee,
					// from,
					// to,
					// description,
					// linkPay,
					// action,
					// image,
					// eventFormat,
					// repeatedDates,
					// imageName,
					// saveRepeatedEvents,
					// removeImage
				);
			},
		},
	};
</script>

<style scoped>
	.btn {
		cursor: pointer;
		flex: 1;
		display: flex;
		text-align: center;
		justify-content: center;
		margin: 5px;
	}
</style>
