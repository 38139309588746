import Api from "@/services/Api";
import { currentUrl } from "../business/constants";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": currentUrl
  // "Access-Control-Allow-Origin": "*"
};

//was the login route:
// headers: {
//   "Content-Type": "application/json",
//   "Access-Control-Allow-Origin": currentUrl
// }

export default {
  register(credentials) {
    return Api().post("register", credentials, {
      withCredentials: true,
      headers: headers
    });
  },
  login(credentials) {
    return Api().post("login", credentials, {
      credentials: "include",
      withCredentials: true,
      headers: headers
    });
  },
  logout(data) {
    return Api().post("logout", data, {
      withCredentials: true,
      headers: headers
    });
  },
  onRemoveFa() {
    return Api().post("onRemoveFa", {}, {
      withCredentials: true,
      headers: headers
    });
  },
  submitTwoFactorCode(data) {
    return Api().post("submitTwoFactor", data, {
      withCredentials: true,
      headers: headers
    });
  },
  resetTwoFactor(token) {
    return Api().post("resettwofaconfirmation/"+token, {}, {
      withCredentials: true,
      headers: headers
    });
  },
  createSpecialEvent(data) {
    return Api().post("createSpecialEvent", data, {
      withCredentials: true,
      headers: headers
    });
  },
  editSpecialEvent(data) {
    return Api().post("editSpecialEvent", data, {
      withCredentials: true,
      headers: headers
    });
  },
  deleteSpecialEvent(data) {
    return Api().post("deleteSpecialEvent", data, {
      withCredentials: true,
      headers: headers
    });
  },
  pushNotification(data) {
    return Api().post("pushNotification", data, {
      withCredentials: true,
      headers: headers
    });
  },
  onChangePassword(data) {
    return Api().post("changepassword", data, {
      withCredentials: true,
      headers: headers
    });
  },
  onResetPassword(data) {
    return Api().post("resetpassword/" + data.token, data, {
      withCredentials: true,
      headers: headers
    });
  },
  onResendEmailConfirmation(data) {
    return Api().post("resendEmailConfirmation", data, {
      withCredentials: true,
      headers: headers
    });
  },
  loadMain(data) {
    return Api().post("loadMain", data, {
      withCredentials: true,
      headers: headers
    });
  },
  changeCompany(data) {
    return Api().post("changeCompany", data, {
      withCredentials: true,
      // credentials: "include",
      headers: headers
    });
  },
  createBar(data) {
    return Api().post("createBar", data, {
      withCredentials: true,
      headers: headers
    });
  },
  loadImages(data) {
    return Api().post("loadImages", data, {
      withCredentials: true,
      headers: headers
    });
  },
  confirmAllImages(data) {
    return Api().post("confirmAllImages", data, {
      withCredentials: true,
      headers: headers
    });
  },
  deleteImageAdmin(data) {
    return Api().post("deleteImageAdmin", data, {
      withCredentials: true,
      headers: headers
    });
  },
  deleteBar(data) {
    return Api().post("deleteBar", data, {
      withCredentials: true,
      headers: headers
    });
  },
  deactivateBar(data) {
    return Api().post("deactivateBar", data, {
      withCredentials: true,
      headers: headers
    });
  },
  getAdContent() {
    return Api().get("getAdContent", {
      withCredentials: true,
      headers: headers
    });
  },
  getUserDetails(data) {
    return Api().post("getUserDetails", data, {
      withCredentials: true,
      headers: headers
    });
  },
  makeUserAdmin(data) {
    return Api().post("makeUserAdmin", data, {
      withCredentials: true,
      headers: headers
    });
  },
  removeUserAdmin(data) {
    return Api().post("removeUserAdmin", data, {
      withCredentials: true,
      headers: headers
    });
  },
  getBarDetails(data) {
    return Api().post("getBarDetails", data, {
      withCredentials: true,
      headers: headers
    });
  },
  getBarData(data) {
    return Api().post("getBarData", data, {
      withCredentials: true,
      headers: headers
    });
  }
};
