import axios from "axios";
import { serverUrl } from "../business/constants";

export default () => {
  return axios.create({
    baseURL: serverUrl
  });
  // if (window.location.origin.includes("localhost")) {
  //   return axios.create({
  //     baseURL: devBaseUrl
  //   });
  // } else {
  //   return axios.create({
  //     baseURL: baseUrl
  //   });
  // }
};
