import Api from "@/services/Api";

export default {
  isAuthed(data) {
    return Api().post("isAuthed", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveDescription(data) {
    return Api().post("saveDescription", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveLinks(data) {
    return Api().post("saveLinks", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveFeatures(data) {
    return Api().post("saveFeatures", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveLocation(data) {
    return Api().post("saveLocation", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  lockLocation(data) {
    return Api().post("lockLocation", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveHappyDrinks(data) {
    return Api().post("saveHappyDrinks", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveOpeningHours(data) {
    return Api().post("saveOpeningHours", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveOpeningHappy(data) {
    return Api().post("saveOpeningHappy", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  addHappyDrink(data) {
    return Api().post("addHappyDrink", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  editHappyDrink(data) {
    return Api().post("editHappyDrink", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  deleteHappyDrink(data) {
    return Api().post("deleteHappyDrink", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  updateTimes(data) {
    return Api().post("updateTimes", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  uploadImage(data) {
    return Api().post("uploadImage", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  saveImages(data) {
    return Api().post("saveImages", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  deleteImage(data) {
    return Api().post("deleteImage", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  addEvent(data) {
    return Api().post("addEvent", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  deleteEvent(data) {
    return Api().post("deleteEvent", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  },
  changeBarActivation(data) {
    return Api().post("changeBarActivation", data, {
      withCredentials: true,
      header: {
        "Content-Type": "application/json"
      }
    });
  }
};
